export var archerDisclosures = {
    amazonDisclosure: {
        title: 'Amazon eGiftCard Disclosure',
        rows: {
            a: [
                'Amazon.com is not a sponsor of this promotion. Except as required by law, Amazon.com Gift Cards ("GCs") cannot be transferred for value or redeemed for cash. GCs may be used only for purchases of eligible goods on Amazon.com or certain of its affiliated websites. GCs cannot be redeemed for purchases of gift cards. Purchases are deducted from the GC balance. To redeem or view a GC balance, visit "Your Account" on Amazon.com. Amazon is not responsible if a GC is lost, stolen, destroyed or used without permission. For complete terms and conditions, see ||www.amazon.com/gc-legal|https://www.amazon.com/gc-legal||. GCs are issued by ACI Gift Cards, Inc., a Washington corporation. All Amazon ™ & © are IP of Amazon.com, Inc. or its affiliates. No expiration date or service fees.',
            ]
        }
    },
    targetDisclosure: {
        title: 'Target Gift Card Disclosure',
        rows: {
            a: [
                'Redeemable for merchandise or services (other than gift cards and prepaid cards) at Target stores in the U.S. or ||Target.com|http://target.com|| and cannot be redeemed for cash or credit except where required by law. For balance information, visit ||Target.com/checkbalance|http://Target.com/checkbalance|| or call 1-800-544-2943. To replace the remaining value on a lost, stolen or damaged card with the original purchase receipt, call 1-800-544-2943.  ©2023 Target Brands, Inc. The Bullseye Design and Target are registered trademarks of Target Brands, Inc.  Target is not a participating partner in or sponsor of this offer.',
            ]
        }
    },
    starbucksDisclosure: {
        title: 'Starbucks Gift Card Disclosure',
        rows: {
            a: [
                'Treat this Card like cash. Reload your Card, check your balance and find out how to register and protect your Card balance at participating stores, Starbucks.com/card or 1-800-782-7282. Cannot be redeemed for cash unless required by law. Refunds only provided for unused Cards with the original receipt. This Card does not expire, nor does Starbucks charge fees. Complete terms and conditions available on our website. Use of this Card constitutes acceptance of these terms and conditions.',
            ]
        }
    },
    lowesDisclosure: {
        title: "Lowe's Gift Card Disclosure",
        rows: {
            a: [
                'This is a Lowe’s Gift Card and is valid in US only.  This is not a credit/debit card and has no implied warranties.  This card is not redeemable for cash unless required by law and cannot be used to make payments on any charge account or used to purchase any other retailer or third-party gift card.  Lowe’s reserves the right to deactivate or reject any Gift Card issued or procured through fraudulent activity.  Lost or stolen Gift Cards can only be replaced upon presentation of original sales receipt for any remaining balance.  It will be void if altered or defaced.  To check your Lowe’s Gift Card balance: visit ||Lowes.com/GiftCards|https://Lowes.com/GiftCards||; call 1-800-560-7172 or see Customer Service Desk at any Lowe’s store.',
            ]
        }
    },
    mastercardDisclosure: {
        header: ['Disbursement Prepaid Mastercard®', 'Virtual Card Fee Disclosure'],
        top: {
            a: {
                label: 'Monthly fee',
                value: '$0.00'
            },
            b: {
                label: 'Per Purchase',
                value: '$0.00'
            },
            c: {
                label: 'ATM Withdrawal',
                value: 'NA'
            },
            d: {
                label: 'Cash Reload',
                value: 'NA'
            }
        },
        lines: {
            a: {
                label: 'ATM Balance Inquiry ~~(in network or out-of-network)~~',
                value: 'NA'
            },
            b: {
                label: 'Customer service ~~(automated or live agent)~~',
                value: '$0.00'
            },
            c: {
                label: 'Inactivity ~~(after 12 months with no transactions)~~',
                value: '$0.95 ~~per month~~'
            },
            d: '**We charge 3 other types of fees.** Here are some of them',
            e: {
                label: 'Foreign Transaction Fee',
                value: '2%'
            },
            f: {
                label: 'Card Replacement Fee, standard delivery',
                value: '$6.95'
            }
        },
        bottom: [
            'No overdraft/credit feature.',
            '',
            'Register your card for FDIC insurance eligibility and other protections.',
            'For general information about prepaid accounts visit __cfpb.gov/prepaid__.',
            'For details and conditions for all fees and services call **844-340-1929 (toll free in USA); 682-324-9995 (outside USA)** or visit **www.MyPrepaidCenter.com**.',
        ],
        footer: [
            'Disbursements Prepaid Mastercard Virtual Card is issued by Pathward®, N.A., Member FDIC, pursuant to license by Mastercard International Incorporated. Card is serviced by Blackhawk Network.',
            '',
            'Mastercard and the circles design are registered trademarks of Mastercard International Incorporated. Card has no cash access and cannot be used for recurring payments. Can be used where Debit Mastercard is accepted for online, phone/mail orders, or in stores that accept mobile wallet. Valid for up to 12 months. Funds do not expire and may be available after the expiration date, inactivity fee may apply. Terms and conditions apply.',
        ]
    },
    physicalMastercardDisclosure: {
        header: ['Disbursement Prepaid Mastercard®', 'Physical Card Fee Disclosure'],
        top: {
            a: {
                label: 'Monthly fee',
                value: '$0.00'
            },
            b: {
                label: 'Per Purchase',
                value: '$0.00'
            },
            c: {
                label: 'ATM Withdrawal',
                value: 'NA'
            },
            d: {
                label: 'Cash Reload',
                value: 'NA'
            }
        },
        lines: {
            a: {
                label: 'ATM Balance Inquiry ~~(in network or out-of-network)~~',
                value: 'NA'
            },
            b: {
                label: 'Customer service ~~(automated or live agent)~~',
                value: '$0.00'
            },
            c: {
                label: 'Inactivity ~~(after 12 months with no transactions)~~',
                value: '$0.95 ~~per month~~'
            },
            d: '**We charge 3 other types of fees.** Here are some of them',
            e: {
                label: 'Foreign Transaction Fee',
                value: '2%'
            },
            f: {
                label: 'Card Replacement Fee, standard delivery',
                value: '$6.95'
            }
        },
        bottom: [
            'No overdraft/credit feature.',
            '',
            'Register your card for FDIC insurance eligibility and other protections.',
            'For general information about prepaid accounts visit __cfpb.gov/prepaid__.',
            'For details and conditions for all fees and services call **844-340-1929 (toll free in USA); 682-324-9995 (outside USA)** or visit **www.MyPrepaidCenter.com**.',
        ],
        footer: [
            'Disbursements Prepaid Mastercard Card is issued by Pathward®, National Association, Member FDIC, pursuant to a license from Mastercard International Incorporated. Card is serviced by Blackhawk Network.',
            '',
            'Mastercard and the circles design are registered trademarks of Mastercard International Incorporated. Card has no cash access and cannot be used for recurring payments. Can be used where Debit Mastercard is accepted for online, phone/mail orders, or in stores that accept mobile wallet. Valid for up to 12 months. Funds do not expire and may be available after the expiration date, inactivity fee may apply. Terms and conditions apply.',
        ]
    }
};
