import { createFrame } from './helpers';
var sharedDataFrame = null;
export function createSharedDataFrame(root, key, params) {
    if (sharedDataFrame && sharedDataFrame.parentElement) {
        sharedDataFrame.parentElement.removeChild(sharedDataFrame);
    }
    sharedDataFrame = createFrame('/index.html?' +
        encodeURIComponent(key) +
        '&' +
        encodeURIComponent(JSON.stringify({ params: params, flow: 'shared-data' })));
    root.appendChild(sharedDataFrame);
}
