export var venmoFaq = {
    title: 'Venmo FAQs',
    questions: {
        a: {
            Q: 'What is Venmo?',
            A: 'Venmo is an online payment system that allows you to receive money with just your email address or phone number. You can have your balance in your Venmo account, and if you choose, you can also link your bank account. For more details, please visit ||venmo.com|https://venmo.com||.'
        },
        b: {
            Q: 'Do I need an existing Venmo account to select Venmo as my payment method?',
            A: 'You can choose Venmo even if you do not already have an existing account! If you do not have an existing account at the time of payment, you will receive a notification which will allow you to set up an account and receive your payment.'
        }
    }
};
