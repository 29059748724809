export var bankAccountHelp = {
    header: 'Where can I find my Routing and Account Numbers?',
    sections: [
        {
            header: 'Online Banking',
            content: 'Most of the online banking websites display the account and routing number in the account details.'
        },
        {
            header: 'Mobile App',
            content: 'Most of the mobile banking applications display the account and routing number in the account details.'
        },
        {
            header: 'At the Bottom of a Check',
            content: 'The routing number is a nine-digit number that apprears in the bottom left corner of the check. The account number is usually from 10 to 15 digits long and appears to the right of the routing number and to the left of the check number'
        },
        {
            uri: '/agreements/assets/check-image.png'
        },
    ]
};
