export var interacFaq = {
    title: 'Interac Frequently Asked Questions',
    questions: {
        a: {
            Q: 'What information is required to choose the Interac payment option?',
            A: 'Email address.'
        },
        b: {
            Q: 'Where do the funds go?',
            A: 'Directly to your Interac account.'
        }
    }
};
