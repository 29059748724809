export var amazonFaq = {
    title: 'Amazon eGift Card Disclosure and FAQs',
    questions: {
        a: {
            Q: 'What is an Amazon eGift Card?',
            A: 'Amazon eGift Cards allow you to spend stored value at amazon.com and only require you to provide your email address.'
        },
        b: {
            Q: 'Where can I find more information about Amazon eGift Cards?',
            A: 'Please visit ||amazon.com/giftcards|https://amazon.com/giftcards||.'
        }
    }
};
