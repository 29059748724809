export var mastercardDisclosure = {
    header: ['Prepaid Mastercard® Fee Disclosure and FAQs'],
    top: {
        a: {
            label: 'Monthly fee',
            value: '$0.00'
        },
        b: {
            label: 'Per Purchase',
            value: '$0.00'
        },
        c: {
            label: 'ATM Withdrawal',
            value: 'NA'
        },
        d: {
            label: 'Cash Reload',
            value: 'NA'
        }
    },
    lines: {
        a: {
            label: 'ATM Balance Inquiry ~~(in network or out-of-network)~~',
            value: 'NA'
        },
        b: {
            label: 'Customer service ~~(automated or live agent)~~',
            value: '$0.00'
        },
        c: {
            label: 'Inactivity ~~(after 12 months with no transactions)~~',
            value: '$0.95 ~~per month~~'
        },
        d: '**We charge 3 other types of fees.** Here are some of them',
        e: {
            label: 'Foreign Transaction Fee',
            value: '2%'
        },
        f: {
            label: 'Card Replacement Fee, standard delivery',
            value: '$6.95'
        }
    },
    bottom: [
        'No overdraft/credit feature.',
        '',
        'Register your card for FDIC insurance eligibility and other protections.',
        'For general information about prepaid accounts visit __cfpb.gov/prepaid__.',
        'For details and conditions for all fees and services call **844-340-1929 (toll free in USA); 682-324-9995 (outside USA)** or visit **www.MyPrepaidCenter.com**.',
    ],
    footer: [
        'Disbursements Prepaid Mastercard Virtual Card is issued by Pathward®, N.A., Member FDIC, pursuant to license by Mastercard International Incorporated. Card is serviced by Blackhawk Network.',
        '',
        'Mastercard and the circles design are registered trademarks of Mastercard International Incorporated. Card has no cash access and cannot be used for recurring payments. Can be used where Debit Mastercard is accepted for online, phone/mail orders, or in stores that accept mobile wallet. Valid for up to 12 months. Funds do not expire and may be available after the expiration date, inactivity fee may apply. Terms and conditions apply.',
    ]
};
