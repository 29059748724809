export var errors = {
    generic: {
        header: 'System Currently Unavailable',
        message: 'We apologize for the inconvenience, but the system is currently unavailable. Please try again later. Thank you.'
    },
    emailQuota: {
        header: 'Notification',
        message: 'We are experiencing a spike in claim submissions. Please try again tomorrow.'
    },
    emailRate: {
        header: 'Notification',
        message: 'We are experiencing a spike in claim submissions. Please try again in a few minutes.'
    }
};
