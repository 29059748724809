export var targetFaq = {
    title: 'Target Gift Card Disclosure and FAQs',
    questions: {
        a: {
            Q: 'What is a Target eGiftCard?',
            A: 'Target eGift Cards allow you to spend stored value at target.com or in Target stores and only require you to provide your email address.'
        },
        b: {
            Q: 'Where can I find more information regarding Target eGiftCards?',
            A: 'Please visit ||https://www.target.com/c/target-giftcards/-/N-5xsxt||.'
        }
    }
};
