export var amazonFaq = {
    title: 'Amazon Gift Card Frequently Asked Questions',
    questions: {
        a: {
            Q: 'What is an Amazon eGift Card?',
            A: 'An Amazon eGift Card allows you to redeem the stored value at ||www.amazon.com|https://www.amazon.com||.'
        },
        b: {
            Q: 'What information is required to choose the Amazon Gift Card payment option?',
            A: 'Email address.'
        },
        c: {
            Q: 'Where do the funds go?',
            A: 'When you receive an Amazon eGift, you can apply it to your Amazon account or apply it at the time of checkout on ||www.amazon.com|https://www.amazon.com||.'
        },
        d: {
            Q: 'Can I choose an Amazon eGift Card if I do not yet have an Amazon account?',
            A: 'Yes. Your eGift Card notification email will include a code that can be used to apply your funds at the time of checkout on ||www.amazon.com|https://www.amazon.com||.'
        }
    }
};
