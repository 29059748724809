export var vMasterCardFaq = {
    title: 'Disbursements Prepaid Mastercard® Virtual Card Fee Disclosure and FAQs',
    questions: {
        a: {
            Q: 'How do I use my Virtual Prepaid Mastercard?',
            A: [
                'You can activate your Virtual Prepaid Mastercard by clicking on the link in your payment notification email. Your virtual card can be used in the following ways:',
                {
                    type: 'list',
                    items: [
                        'Make online purchases using your card number (from any merchants that accept Mastercard)',
                        'Add the virtual Prepaid Mastercard to ApplePay, Samsung Pay, and other compatible mobile wallets then spend like a typical mobile wallet',
                        'Spend in stores where merchants are willing to key in your card number.',
                    ]
                },
            ]
        },
        b: {
            Q: 'Can I request a physical card?',
            A: 'Physical cards can be requested by U.S. residents for a fee.'
        },
        c: {
            Q: 'Can I transfer my funds from my Virtual Prepaid Mastercard?',
            A: 'You can transfer the remaining card balance to an alternative payment method.'
        }
    }
};
