export var starbucksFaq = {
    title: 'Starbucks Gift Card Frequently Asked Questions',
    questions: {
    // a: {
    //   Q: 'What is an Starbucks eGift Card?',
    //   A: 'No, seriously, what is it?',
    // },
    // b: {
    //   Q: 'What information is required to choose the Starbucks Gift Card payment option?',
    //   A: 'Email address.',
    // },
    // c: {
    //   Q: 'Where do the funds go?',
    //   A: 'When you receive an Starbucks eGift, you can apply it to your Starbucks account.',
    // },
    // d: {
    //   Q: 'Can I choose an Starbucks eGift Card if I do not yet have an Starbucks account?',
    //   A: 'Yes. Your eGift Card notification email will include a code that can be used do something.',
    // },
    }
};
