export var pMasterCardFaq = {
    title: 'Physical Prepaid Mastercard® Frequently Asked Questions',
    questions: {
        b: {
            Q: 'How do I activate my Physical Prepaid Mastercard?',
            A: 'You can activate your Physical Prepaid Mastercard by following the instructions provided in the envelope with your Physical Prepaid Mastercard.'
        },
        c: {
            Q: 'How do I use my Physical Prepaid Mastercard?',
            A: [
                'A: To make purchases with your Physical Prepaid Mastercard, please do one of the following:',
                {
                    type: 'list',
                    items: [
                        'Swipe your card wherever Mastercard is accepted OR',
                        'Use the 16-digit card number and make an online purchase (from any online merchant that accepts Mastercard)',
                    ]
                },
            ]
        },
        d: {
            Q: 'Can I transfer funds to my bank account or to another payment method?',
            A: 'You can transfer the remaining card balance to other offered payment methods (e.g., PayPal). Geographic restrictions apply.'
        }
    }
};
