export var paypalFaq = {
    title: 'PayPal Frequently Asked Questions',
    questions: {
        a: {
            Q: 'What information is required to choose the PayPal payment option?',
            A: 'Email address or phone number.'
        },
        b: {
            Q: 'Where do the funds go?',
            A: 'Directly to your PayPal account.'
        },
        c: {
            Q: 'Can I transfer funds to my bank account?',
            A: 'Yes. Once the funds are deposited into your PayPal account, you can move the money to your bank account using the normal PayPal transfer process.'
        },
        d: {
            Q: 'Can I choose a PayPal payment if I do not yet have a PayPal account?',
            A: 'Yes. Your payment notification email will include a link to create a PayPal account and receive your payment.'
        }
    }
};
