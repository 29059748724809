import { defaultLog } from '../logger';
import { createDispatcher } from './app-dispatcher';
import { createFrame } from './helpers';
import { handlePayload } from './message-handler';
import { createSharedDataFrame } from './shared-data';
export function registerApi() {
    var container = window;
    function dstTags(options) {
        var root = document.getElementsByTagName('body')[0];
        if (!root) {
            defaultLog.error('No BODY element');
            return;
        }
        var dispatcher = createDispatcher(root, options.key);
        createSharedDataFrame(root, options.key, { ln: options.ln, country: options.country });
        var dstTags = Array.from(document.getElementsByTagName('dst'));
        dstTags.forEach(function (element) {
            var index = dispatcher.registerPayload({
                flow: 'magic-tag',
                params: Object.fromEntries(Array.from(element.attributes).map(function (i) { return [i.name, i.value]; }))
            });
            var frame = createFrame('/flow.html?' + encodeURIComponent(index), {
                width: '100%',
                height: '0'
            });
            element.insertAdjacentElement('afterend', frame);
            dispatcher.registerIframe(frame, index, true);
            var eventListener = function (event) {
                if (event.data.source === 'dst') {
                    handlePayload(dispatcher, event);
                }
            };
            window.addEventListener('message', eventListener);
            container._dstUnregisterTags = function () {
                window.removeEventListener('message', eventListener);
            };
        });
    }
    if (container._dstUnregisterTags) {
        container._dstUnregisterTags();
    }
    container.dstTags = dstTags;
}
