export var achFaq = {
    title: 'Direct Deposit Frequently Asked Questions',
    questions: {
        a: {
            Q: 'Is it safe to provide my bank routing number and account number?',
            A: 'We encrypt your account information using 256 bit AES encryption. But if you are uncomfortable providing your bank routing number and account number, please select another payment option.'
        },
        b: {
            Q: 'Where do the funds go?',
            A: 'Directly to your bank account.'
        }
    }
};
