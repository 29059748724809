export var lowesFaq = {
    title: "Lowe's Gift Card Disclosure and FAQs",
    questions: {
        a: {
            Q: 'What is a Lowe’s eGift Card?',
            A: 'Lowe’s eGift Cards allow you to spend stored value at lowes.com or in Lowe’s stores and only require you to provide your email address.'
        },
        b: {
            Q: 'Where can I find more information about Lowe’s eGift Cards?',
            A: 'Please visit ||Lowes.com/GiftCards|https://Lowes.com/GiftCards||.'
        }
    }
};
