export var eftFaq = {
    title: 'EFT Canada Frequently Asked Questions',
    questions: {
        a: {
            Q: 'Is it safe to provide my banking information?',
            A: 'We encrypt your account information using 256 bit AES encryption. If you are uncomfortable providing your banking information, please select another payment option.'
        },
        b: {
            Q: 'Where do the funds go?',
            A: 'Directly to your bank account.'
        }
    }
};
