var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function createDispatcher(root, apiKey, defaultFrame) {
    var sharedData = null;
    var payloads = defaultFrame ? [{}] : [];
    var frames = defaultFrame ? { 0: defaultFrame } : {};
    function postMessage(destination, type, payload) {
        destination.postMessage({
            source: 'dst',
            type: type,
            payload: payload
        }, '*');
    }
    function sendSharedData(destination) {
        if (sharedData === null) {
            setTimeout(function () { return sendSharedData(destination); }, 100);
        }
        else {
            postMessage(destination, 'shared-data', __assign(__assign({}, sharedData), { hostUrl: document.location.href }));
        }
    }
    function setSharedData(data) {
        sharedData = data;
    }
    function registerPayload(payload) {
        return payloads.push(payload) - 1;
    }
    function registerIframe(frame, index, skipAppendChild) {
        if (!skipAppendChild) {
            root.appendChild(frame);
        }
        frames[index] = frame;
    }
    function unregisterIframe(index) {
        var frame = frames[index];
        if (frame) {
            if (frame.parentElement === root) {
                root.removeChild(frame);
            }
            delete frames[index];
        }
    }
    function setFrameHeight(index, height) {
        var frame = frames[index];
        if (frame) {
            frame.style.height = height + 10 + 'px';
        }
    }
    function sendPayload(index) {
        var payload = payloads[index];
        var frame = frames[index];
        if (frame === null || frame === void 0 ? void 0 : frame.contentWindow) {
            postMessage(frame === null || frame === void 0 ? void 0 : frame.contentWindow, 'payload', __assign(__assign({}, payload), { apiKey: apiKey }));
        }
    }
    function sendHostUrl(destination) {
        postMessage(destination, 'send-host-url', {
            hostUrl: document.location.href
        });
    }
    function propagateEvent(eventType, payload) {
        payloads.forEach(function (_, index) {
            var _a;
            var frame = frames[index];
            (_a = frame === null || frame === void 0 ? void 0 : frame.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage({
                source: 'dst',
                type: 'interframe-event',
                payload: payload,
                eventType: eventType
            }, '*');
        });
    }
    return {
        registerPayload: registerPayload,
        registerIframe: registerIframe,
        unregisterIframe: unregisterIframe,
        sendPayload: sendPayload,
        setFrameHeight: setFrameHeight,
        propagateEvent: propagateEvent,
        sendSharedData: sendSharedData,
        setSharedData: setSharedData,
        sendHostUrl: sendHostUrl
    };
}
