/**
 * Legacy dstPaymentform() definition
 */
import base64 from 'base-64';
import { createLogger } from '../logger';
import { createDispatcher } from './app-dispatcher';
import { createFrame } from './helpers';
import { handlePayload } from './message-handler';
import { createSharedDataFrame } from './shared-data';
var embeddedFrame, widgetFrame;
var log = createLogger('host');
export function registerLegacyAPI() {
    log.info('Registering legacy API');
    var container = window;
    function dstPaymentForm(root, callback, options) {
        __DEBUG && log.debug('Legacy API', 'dstPaymentForm', [root, callback, options]);
        var defaults = {
            cid: '',
            lastName: '',
            ln: 'en',
            sc: false,
            verify: true,
            paperCheck: 'link',
            acceptCompanyName: false,
            choiceConsent: false,
            country: '',
            flow: ''
        };
        var opt = (typeof callback === 'object' ? callback : options) || defaults;
        var cb = typeof callback === 'function' ? callback : undefined;
        var key = root.getAttribute('key');
        if (embeddedFrame && embeddedFrame.parentElement === root) {
            root.removeChild(embeddedFrame);
        }
        var allowedKeys = Object.keys(defaults);
        var params = {};
        Object.keys(opt).forEach(function (k) {
            var v = opt[k];
            if (allowedKeys.indexOf(k) !== -1 && (typeof v === 'string' || typeof v === 'boolean' || typeof v === 'number')) {
                params[k] = v;
            }
        });
        var texts = opt.texts || {};
        embeddedFrame = createFrame('/index.html?' + encodeURIComponent(key) + '&' + encodeURIComponent(JSON.stringify(params)), { width: '100%' });
        root.appendChild(embeddedFrame);
        createSharedDataFrame(root, key, params);
        function createWidget(method) {
            widgetFrame = createFrame('/widget.html?' +
                encodeURIComponent(method) +
                '&' +
                encodeURIComponent(key) +
                '&' +
                encodeURIComponent(JSON.stringify(params)), {
                width: '100%',
                height: '100%'
            });
            widgetFrame.style.left = '0';
            widgetFrame.style.top = '0';
            widgetFrame.style.position = 'fixed';
            widgetFrame.style.backgroundColor = 'transparent';
            widgetFrame.style.zIndex = '999';
            root.appendChild(widgetFrame);
        }
        function destroyWidget() {
            __DEBUG &&
                log.debug('ON_CLOSE', widgetFrame, widgetFrame === null || widgetFrame === void 0 ? void 0 : widgetFrame.parentElement, root, (widgetFrame === null || widgetFrame === void 0 ? void 0 : widgetFrame.parentElement) === root);
            if (widgetFrame) {
                if (widgetFrame.parentElement === root) {
                    root.removeChild(widgetFrame);
                    widgetFrame = null;
                }
            }
        }
        var dispatcher = createDispatcher(root, key, embeddedFrame);
        var eventListener = function (event) {
            if (event.data.source === 'dst') {
                __DEBUG && log.debug('Event received', event.data.type, event);
                if (embeddedFrame.parentElement !== root) {
                    __DEBUG && log.debug('This event listener is stale, ignoring.');
                    return;
                }
                switch (event.data.type) {
                    case 'get-options':
                        if (event.source) {
                            var source = event.source;
                            source.postMessage({
                                type: 'send-options',
                                texts: texts[event.data.ln || 'en'],
                                theme: opt.theme || {},
                                sortOrder: opt.sortOrder || [],
                                tracker: opt.tracker || '',
                                config: opt.config || {}
                            }, '*');
                        }
                        break;
                    case 'methods-displayed':
                        if (opt.onMethodsDisplayed) {
                            opt.onMethodsDisplayed(event.data.country);
                        }
                        break;
                    case 'method-selected':
                        createWidget(base64.encode(encodeURIComponent(JSON.stringify({
                            method: event.data.value,
                            cid: event.data.cid,
                            lastName: event.data.lastName,
                            country: event.data.country,
                            fee: event.data.fee,
                            amount: event.data.amount,
                            extraVerification: event.data.extraVerification,
                            options: JSON.stringify(params)
                        }))));
                        if (opt.onDialogDisplayed) {
                            opt.onDialogDisplayed(event.data.value);
                        }
                        break;
                    case 'close':
                        destroyWidget();
                        if (opt.onDialogClosed) {
                            opt.onDialogClosed(event.data.submitted);
                        }
                        break;
                    case 'data-collected':
                        embeddedFrame.contentWindow && embeddedFrame.contentWindow.postMessage(event.data, '*');
                        if (event.data.info.token) {
                            if (cb) {
                                cb(event.data.info);
                            }
                            if (opt.onSubmitted) {
                                opt.onSubmitted(event.data.info);
                            }
                        }
                        break;
                    case 'info-popup':
                        createWidget(base64.encode(encodeURIComponent(JSON.stringify({
                            info: event.data.method
                        }))));
                        break;
                    default: {
                        handlePayload(dispatcher, event);
                        break;
                    }
                }
            }
        };
        window.addEventListener('message', eventListener);
        container._dstUnregister = function () {
            window.removeEventListener('message', eventListener);
        };
    }
    if (container._dstUnregister) {
        container._dstUnregister();
    }
    container.dstPaymentForm = dstPaymentForm;
}
