export var lowesFaq = {
    title: "Lowe's Gift Card Frequently Asked Questions",
    questions: {
    // a: {
    //   Q: "What is an Lowe's eGift Card?",
    //   A: 'No, seriously, what is it?',
    // },
    // b: {
    //   Q: "What information is required to choose the Lowe's Gift Card payment option?",
    //   A: 'Email address.',
    // },
    // c: {
    //   Q: 'Where do the funds go?',
    //   A: "When you receive an Lowe's eGift, you can apply it to your Lowe's account.",
    // },
    // d: {
    //   Q: "Can I choose an Lowe's eGift Card if I do not yet have an Lowe's account?",
    //   A: 'Yes. Your eGift Card notification email will include a code that can be used do something.',
    // },
    }
};
