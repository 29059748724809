export var zelleFaq = {
    title: 'Zelle FAQs',
    questions: {
        a: {
            Q: 'What is Zelle?',
            A: 'Zelle® is an easy way to receive money directly to your U.S. bank account. With just an email address or U.S. mobile phone number, you can quickly, safely and easily receive money. For more details, please visit ||Zellepay.com|https://Zellepay.com||. '
        },
        b: {
            Q: 'Do I need to be enrolled with Zelle to select Zelle as my payment method?',
            A: 'You can choose Zelle even if you’re not already enrolled! If you’re not enrolled at the time of payment, you will receive a notification which will allow you to enroll and receive your payment.'
        }
    }
};
