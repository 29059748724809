export function getCountryList(countryCodeList) {
    var allowedCountryCodesMap = new Map(countryCodeList.map(function (countryCode) { return [countryCode, true]; }));
    var allowedCountries = countries.filter(function (c) { return allowedCountryCodesMap.has(c.code2); });
    return allowedCountries;
}
export var caProvinces = [
    {
        name: 'Alberta',
        short: 'AB'
    },
    {
        name: 'British Columbia',
        short: 'BC'
    },
    {
        name: 'Manitoba',
        short: 'MB'
    },
    {
        name: 'New Brunswick',
        short: 'NB'
    },
    {
        name: 'Newfoundland And Labrador',
        short: 'NL'
    },
    {
        name: 'Nova Scotia',
        short: 'NS'
    },
    {
        name: 'Nunavut',
        short: 'NU'
    },
    {
        name: 'Northwest Territories',
        short: 'NT'
    },
    {
        name: 'Ontario',
        short: 'ON'
    },
    {
        name: 'Prince Edward Island',
        short: 'PE'
    },
    {
        name: 'Quebec',
        short: 'QC'
    },
    {
        name: 'Saskatchewan',
        short: 'SK'
    },
    {
        name: 'Yukon',
        short: 'YT'
    },
];
export var states = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
    AA: 'Armed Forces Americas (except Canada and United States of America)',
    AE: 'Armed Forces Africa, Canada, Europe, and Middle East',
    AP: 'Armed Forces Pacific',
    AS: 'American Samoa',
    FM: 'Federated States of Micronesia',
    GU: 'Guam',
    MH: 'Marshall Islands',
    MP: 'Northern Mariana Islands',
    PR: 'Puerto Rico',
    PW: 'Palau',
    VI: 'Virgin Islands'
};
export var countries = [
    {
        code2: 'AD',
        name: 'Andorra'
    },
    {
        code2: 'AE',
        name: 'United Arab Emirates'
    },
    {
        code2: 'AF',
        name: 'Afghanistan'
    },
    {
        code2: 'AG',
        name: 'Antigua and Barbuda'
    },
    {
        code2: 'AI',
        name: 'Anguilla'
    },
    {
        code2: 'AL',
        name: 'Albania'
    },
    {
        code2: 'AM',
        name: 'Armenia'
    },
    {
        code2: 'AO',
        name: 'Angola'
    },
    {
        code2: 'AQ',
        name: 'Antarctica'
    },
    {
        code2: 'AR',
        name: 'Argentina'
    },
    {
        code2: 'AS',
        name: 'American Samoa'
    },
    {
        code2: 'AT',
        name: 'Austria'
    },
    {
        code2: 'AU',
        name: 'Australia'
    },
    {
        code2: 'AW',
        name: 'Aruba'
    },
    {
        code2: 'AX',
        name: 'Aland Islands'
    },
    {
        code2: 'AZ',
        name: 'Azerbaijan'
    },
    {
        code2: 'BA',
        name: 'Bosnia and Herzegovina'
    },
    {
        code2: 'BB',
        name: 'Barbados'
    },
    {
        code2: 'BD',
        name: 'Bangladesh'
    },
    {
        code2: 'BE',
        name: 'Belgium'
    },
    {
        code2: 'BF',
        name: 'Burkina Faso'
    },
    {
        code2: 'BG',
        name: 'Bulgaria'
    },
    {
        code2: 'BH',
        name: 'Bahrain'
    },
    {
        code2: 'BI',
        name: 'Burundi'
    },
    {
        code2: 'BJ',
        name: 'Benin'
    },
    {
        code2: 'BL',
        name: 'Saint Barthlemy'
    },
    {
        code2: 'BM',
        name: 'Bermuda'
    },
    {
        code2: 'BN',
        name: 'Brunei Darussalam'
    },
    {
        code2: 'BO',
        name: 'Bolivia (Plurinational State of)'
    },
    {
        code2: 'BQ',
        name: 'Bonaire, Sint Eustatius and Saba'
    },
    {
        code2: 'BR',
        name: 'Brazil'
    },
    {
        code2: 'BS',
        name: 'Bahamas'
    },
    {
        code2: 'BT',
        name: 'Bhutan'
    },
    {
        code2: 'BV',
        name: 'Bouvet Island'
    },
    {
        code2: 'BW',
        name: 'Botswana'
    },
    {
        code2: 'BY',
        name: 'Belarus'
    },
    {
        code2: 'BZ',
        name: 'Belize'
    },
    {
        code2: 'CA',
        name: 'Canada'
    },
    {
        code2: 'CC',
        name: 'Cocos (Keeling) Islands'
    },
    {
        code2: 'CD',
        name: 'Congo (the Democratic Republic of the)'
    },
    {
        code2: 'CF',
        name: 'Central African Republic'
    },
    {
        code2: 'CG',
        name: 'Congo'
    },
    {
        code2: 'CH',
        name: 'Switzerland'
    },
    {
        code2: 'CI',
        name: "Cote d'Ivoire"
    },
    {
        code2: 'CK',
        name: 'Cook Islands'
    },
    {
        code2: 'CL',
        name: 'Chile'
    },
    {
        code2: 'CM',
        name: 'Cameroon'
    },
    {
        code2: 'CN',
        name: 'China'
    },
    {
        code2: 'CO',
        name: 'Colombia'
    },
    {
        code2: 'CR',
        name: 'Costa Rica'
    },
    {
        code2: 'CU',
        name: 'Cuba'
    },
    {
        code2: 'CV',
        name: 'Cabo Verde'
    },
    {
        code2: 'CW',
        name: 'Curacao'
    },
    {
        code2: 'CX',
        name: 'Christmas Island'
    },
    {
        code2: 'CY',
        name: 'Cyprus'
    },
    {
        code2: 'CZ',
        name: 'Czechia'
    },
    {
        code2: 'DE',
        name: 'Germany'
    },
    {
        code2: 'DJ',
        name: 'Djibouti'
    },
    {
        code2: 'DK',
        name: 'Denmark'
    },
    {
        code2: 'DM',
        name: 'Dominica'
    },
    {
        code2: 'DO',
        name: 'Dominican Republic'
    },
    {
        code2: 'DZ',
        name: 'Algeria'
    },
    {
        code2: 'EC',
        name: 'Ecuador'
    },
    {
        code2: 'EE',
        name: 'Estonia'
    },
    {
        code2: 'EG',
        name: 'Egypt'
    },
    {
        code2: 'EH',
        name: 'Western Sahara'
    },
    {
        code2: 'ER',
        name: 'Eritrea'
    },
    {
        code2: 'ES',
        name: 'Spain'
    },
    {
        code2: 'ET',
        name: 'Ethiopia'
    },
    {
        code2: 'FI',
        name: 'Finland'
    },
    {
        code2: 'FJ',
        name: 'Fiji'
    },
    {
        code2: 'FK',
        name: 'Falkland Islands  [Malvinas]'
    },
    {
        code2: 'FM',
        name: 'Micronesia (Federated States of)'
    },
    {
        code2: 'FO',
        name: 'Faroe Islands'
    },
    {
        code2: 'FR',
        name: 'France'
    },
    {
        code2: 'GA',
        name: 'Gabon'
    },
    {
        code2: 'GB',
        name: 'United Kingdom'
    },
    {
        code2: 'GD',
        name: 'Grenada'
    },
    {
        code2: 'GE',
        name: 'Georgia'
    },
    {
        code2: 'GF',
        name: 'French Guiana'
    },
    {
        code2: 'GG',
        name: 'Guernsey'
    },
    {
        code2: 'GH',
        name: 'Ghana'
    },
    {
        code2: 'GI',
        name: 'Gibraltar'
    },
    {
        code2: 'GL',
        name: 'Greenland'
    },
    {
        code2: 'GM',
        name: 'Gambia'
    },
    {
        code2: 'GN',
        name: 'Guinea'
    },
    {
        code2: 'GP',
        name: 'Guadeloupe'
    },
    {
        code2: 'GQ',
        name: 'Equatorial Guinea'
    },
    {
        code2: 'GR',
        name: 'Greece'
    },
    {
        code2: 'GS',
        name: 'South Georgia and the South Sandwich Islands'
    },
    {
        code2: 'GT',
        name: 'Guatemala'
    },
    {
        code2: 'GU',
        name: 'Guam'
    },
    {
        code2: 'GW',
        name: 'Guinea-Bissau'
    },
    {
        code2: 'GY',
        name: 'Guyana'
    },
    {
        code2: 'HK',
        name: 'Hong Kong'
    },
    {
        code2: 'HM',
        name: 'Heard Island and McDonald Islands'
    },
    {
        code2: 'HN',
        name: 'Honduras'
    },
    {
        code2: 'HR',
        name: 'Croatia'
    },
    {
        code2: 'HT',
        name: 'Haiti'
    },
    {
        code2: 'HU',
        name: 'Hungary'
    },
    {
        code2: 'ID',
        name: 'Indonesia'
    },
    {
        code2: 'IE',
        name: 'Ireland'
    },
    {
        code2: 'IL',
        name: 'Israel'
    },
    {
        code2: 'IM',
        name: 'Isle of Man'
    },
    {
        code2: 'IN',
        name: 'India'
    },
    {
        code2: 'IO',
        name: 'British Indian Ocean Territory'
    },
    {
        code2: 'IQ',
        name: 'Iraq'
    },
    {
        code2: 'IR',
        name: 'Iran (Islamic Republic of)'
    },
    {
        code2: 'IS',
        name: 'Iceland'
    },
    {
        code2: 'IT',
        name: 'Italy'
    },
    {
        code2: 'JE',
        name: 'Jersey'
    },
    {
        code2: 'JM',
        name: 'Jamaica'
    },
    {
        code2: 'JO',
        name: 'Jordan'
    },
    {
        code2: 'JP',
        name: 'Japan'
    },
    {
        code2: 'KE',
        name: 'Kenya'
    },
    {
        code2: 'KG',
        name: 'Kyrgyzstan'
    },
    {
        code2: 'KH',
        name: 'Cambodia'
    },
    {
        code2: 'KI',
        name: 'Kiribati'
    },
    {
        code2: 'KM',
        name: 'Comoros'
    },
    {
        code2: 'KN',
        name: 'Saint Kitts and Nevis'
    },
    {
        code2: 'KP',
        name: "Korea (the Democratic People's Republic of)"
    },
    {
        code2: 'KR',
        name: 'Korea (the Republic of)'
    },
    {
        code2: 'KW',
        name: 'Kuwait'
    },
    {
        code2: 'KY',
        name: 'Cayman Islands'
    },
    {
        code2: 'KZ',
        name: 'Kazakhstan'
    },
    {
        code2: 'LA',
        name: "Lao People's Democratic Republic"
    },
    {
        code2: 'LB',
        name: 'Lebanon'
    },
    {
        code2: 'LC',
        name: 'Saint Lucia'
    },
    {
        code2: 'LI',
        name: 'Liechtenstein'
    },
    {
        code2: 'LK',
        name: 'Sri Lanka'
    },
    {
        code2: 'LR',
        name: 'Liberia'
    },
    {
        code2: 'LS',
        name: 'Lesotho'
    },
    {
        code2: 'LT',
        name: 'Lithuania'
    },
    {
        code2: 'LU',
        name: 'Luxembourg'
    },
    {
        code2: 'LV',
        name: 'Latvia'
    },
    {
        code2: 'LY',
        name: 'Libya'
    },
    {
        code2: 'MA',
        name: 'Morocco'
    },
    {
        code2: 'MC',
        name: 'Monaco'
    },
    {
        code2: 'MD',
        name: 'Moldova (the Republic of)'
    },
    {
        code2: 'ME',
        name: 'Montenegro'
    },
    {
        code2: 'MF',
        name: 'Saint Martin (French part)'
    },
    {
        code2: 'MG',
        name: 'Madagascar'
    },
    {
        code2: 'MH',
        name: 'Marshall Islands'
    },
    {
        code2: 'MK',
        name: 'Republic of North Macedonia'
    },
    {
        code2: 'ML',
        name: 'Mali'
    },
    {
        code2: 'MM',
        name: 'Myanmar'
    },
    {
        code2: 'MN',
        name: 'Mongolia'
    },
    {
        code2: 'MO',
        name: 'Macao'
    },
    {
        code2: 'MP',
        name: 'Northern Mariana Islands'
    },
    {
        code2: 'MQ',
        name: 'Martinique'
    },
    {
        code2: 'MR',
        name: 'Mauritania'
    },
    {
        code2: 'MS',
        name: 'Montserrat'
    },
    {
        code2: 'MT',
        name: 'Malta'
    },
    {
        code2: 'MU',
        name: 'Mauritius'
    },
    {
        code2: 'MV',
        name: 'Maldives'
    },
    {
        code2: 'MW',
        name: 'Malawi'
    },
    {
        code2: 'MX',
        name: 'Mexico'
    },
    {
        code2: 'MY',
        name: 'Malaysia'
    },
    {
        code2: 'MZ',
        name: 'Mozambique'
    },
    {
        code2: 'NA',
        name: 'Namibia'
    },
    {
        code2: 'NC',
        name: 'New Caledonia'
    },
    {
        code2: 'NE',
        name: 'Niger'
    },
    {
        code2: 'NF',
        name: 'Norfolk Island'
    },
    {
        code2: 'NG',
        name: 'Nigeria'
    },
    {
        code2: 'NI',
        name: 'Nicaragua'
    },
    {
        code2: 'NL',
        name: 'Netherlands'
    },
    {
        code2: 'NO',
        name: 'Norway'
    },
    {
        code2: 'NP',
        name: 'Nepal'
    },
    {
        code2: 'NR',
        name: 'Nauru'
    },
    {
        code2: 'NU',
        name: 'Niue'
    },
    {
        code2: 'NZ',
        name: 'New Zealand'
    },
    {
        code2: 'OM',
        name: 'Oman'
    },
    {
        code2: 'PA',
        name: 'Panama'
    },
    {
        code2: 'PE',
        name: 'Peru'
    },
    {
        code2: 'PF',
        name: 'French Polynesia'
    },
    {
        code2: 'PG',
        name: 'Papua New Guinea'
    },
    {
        code2: 'PH',
        name: 'Philippines'
    },
    {
        code2: 'PK',
        name: 'Pakistan'
    },
    {
        code2: 'PL',
        name: 'Poland'
    },
    {
        code2: 'PM',
        name: 'Saint Pierre and Miquelon'
    },
    {
        code2: 'PN',
        name: 'Pitcairn'
    },
    {
        code2: 'PR',
        name: 'Puerto Rico'
    },
    {
        code2: 'PS',
        name: 'Palestine, State of'
    },
    {
        code2: 'PT',
        name: 'Portugal'
    },
    {
        code2: 'PW',
        name: 'Palau'
    },
    {
        code2: 'PY',
        name: 'Paraguay'
    },
    {
        code2: 'QA',
        name: 'Qatar'
    },
    {
        code2: 'RE',
        name: 'Réunion'
    },
    {
        code2: 'RO',
        name: 'Romania'
    },
    {
        code2: 'RS',
        name: 'Serbia'
    },
    {
        code2: 'RU',
        name: 'Russian Federation'
    },
    {
        code2: 'RW',
        name: 'Rwanda'
    },
    {
        code2: 'SA',
        name: 'Saudi Arabia'
    },
    {
        code2: 'SB',
        name: 'Solomon Islands'
    },
    {
        code2: 'SC',
        name: 'Seychelles'
    },
    {
        code2: 'SD',
        name: 'Sudan'
    },
    {
        code2: 'SE',
        name: 'Sweden'
    },
    {
        code2: 'SG',
        name: 'Singapore'
    },
    {
        code2: 'SH',
        name: 'Saint Helena, Ascension and Tristan da Cunha'
    },
    {
        code2: 'SI',
        name: 'Slovenia'
    },
    {
        code2: 'SJ',
        name: 'Svalbard and Jan Mayen'
    },
    {
        code2: 'SK',
        name: 'Slovakia'
    },
    {
        code2: 'SL',
        name: 'Sierra Leone'
    },
    {
        code2: 'SM',
        name: 'San Marino'
    },
    {
        code2: 'SN',
        name: 'Senegal'
    },
    {
        code2: 'SO',
        name: 'Somalia'
    },
    {
        code2: 'SR',
        name: 'Suriname'
    },
    {
        code2: 'SS',
        name: 'South Sudan'
    },
    {
        code2: 'ST',
        name: 'Sao Tome and Principe'
    },
    {
        code2: 'SV',
        name: 'El Salvador'
    },
    {
        code2: 'SX',
        name: 'Sint Maarten (Dutch part)'
    },
    {
        code2: 'SY',
        name: 'Syrian Arab Republic'
    },
    {
        code2: 'SZ',
        name: 'Eswatini'
    },
    {
        code2: 'TC',
        name: 'Turks and Caicos Islands'
    },
    {
        code2: 'TD',
        name: 'Chad'
    },
    {
        code2: 'TF',
        name: 'French Southern Territories'
    },
    {
        code2: 'TG',
        name: 'Togo'
    },
    {
        code2: 'TH',
        name: 'Thailand'
    },
    {
        code2: 'TJ',
        name: 'Tajikistan'
    },
    {
        code2: 'TK',
        name: 'Tokelau'
    },
    {
        code2: 'TL',
        name: 'Timor-Leste'
    },
    {
        code2: 'TM',
        name: 'Turkmenistan'
    },
    {
        code2: 'TN',
        name: 'Tunisia'
    },
    {
        code2: 'TO',
        name: 'Tonga'
    },
    {
        code2: 'TR',
        name: 'Turkey'
    },
    {
        code2: 'TT',
        name: 'Trinidad and Tobago'
    },
    {
        code2: 'TV',
        name: 'Tuvalu'
    },
    {
        code2: 'TW',
        name: 'Taiwan (Province of China)'
    },
    {
        code2: 'TZ',
        name: 'Tanzania, United Republic of'
    },
    {
        code2: 'UA',
        name: 'Ukraine'
    },
    {
        code2: 'UG',
        name: 'Uganda'
    },
    {
        code2: 'UM',
        name: 'United States Minor Outlying Islands'
    },
    {
        code2: 'US',
        name: 'United States of America'
    },
    {
        code2: 'UY',
        name: 'Uruguay'
    },
    {
        code2: 'UZ',
        name: 'Uzbekistan'
    },
    {
        code2: 'VA',
        name: 'Holy See'
    },
    {
        code2: 'VC',
        name: 'Saint Vincent and the Grenadines'
    },
    {
        code2: 'VE',
        name: 'Venezuela (Bolivarian Republic of)'
    },
    {
        code2: 'VG',
        name: 'Virgin Islands (British)'
    },
    {
        code2: 'VI',
        name: 'Virgin Islands (U.S.)'
    },
    {
        code2: 'VN',
        name: 'Viet Nam'
    },
    {
        code2: 'VU',
        name: 'Vanuatu'
    },
    {
        code2: 'WF',
        name: 'Wallis and Futuna'
    },
    {
        code2: 'WS',
        name: 'Samoa'
    },
    {
        code2: 'YE',
        name: 'Yemen'
    },
    {
        code2: 'YT',
        name: 'Mayotte'
    },
    {
        code2: 'ZA',
        name: 'South Africa'
    },
    {
        code2: 'ZM',
        name: 'Zambia'
    },
    {
        code2: 'ZW',
        name: 'Zimbabwe'
    },
].sort(function (a, b) {
    if (a.name < b.name)
        return -1;
    if (a.name > b.name)
        return 1;
    return 0;
});
export var iatCountries = ['US', 'MX', 'PA', 'PR'];
export var mastercardCountries = [
    'AD',
    'AE',
    'AG',
    'AI',
    'AM',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AW',
    'AX',
    'AZ',
    'BB',
    'BE',
    'BG',
    'BH',
    'BJ',
    'BL',
    'BM',
    'BN',
    'BQ',
    'BS',
    'BT',
    'BV',
    'BW',
    'BZ',
    'CA',
    'CC',
    'CG',
    'CH',
    'CI',
    'CK',
    'CR',
    'CV',
    'CW',
    'CX',
    'CY',
    'CZ',
    'DE',
    'DK',
    'DM',
    'DO',
    'EE',
    'EG',
    'EH',
    'ES',
    'FI',
    'FJ',
    'FK',
    'FM',
    'FO',
    'FR',
    'GB',
    'GD',
    'GE',
    'GF',
    'GG',
    'GH',
    'GL',
    'GM',
    'GP',
    'GR',
    'GS',
    'GT',
    'GU',
    'GY',
    'HK',
    'HM',
    'HR',
    'HU',
    'ID',
    'IE',
    'IL',
    'IM',
    'IO',
    'IS',
    'IT',
    'JE',
    'JM',
    'JO',
    'KH',
    'KI',
    'KN',
    'KR',
    'KW',
    'KY',
    'KZ',
    'LC',
    'LI',
    'LK',
    'LS',
    'LT',
    'LU',
    'LV',
    'MA',
    'MC',
    'MD',
    'MF',
    'MH',
    'MK',
    'MP',
    'MQ',
    'MS',
    'MT',
    'MU',
    'MV',
    'MX',
    'MY',
    'NA',
    'NC',
    'NF',
    'NG',
    'NL',
    'NO',
    'NR',
    'NU',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PF',
    'PH',
    'PL',
    'PM',
    'PN',
    'PR',
    'PS',
    'PT',
    'PW',
    'QA',
    'RE',
    'RO',
    'RS',
    'RW',
    'SA',
    'SB',
    'SC',
    'SE',
    'SH',
    'SI',
    'SJ',
    'SK',
    'SM',
    'SR',
    'ST',
    'SV',
    'SX',
    'TC',
    'TF',
    'TH',
    'TK',
    'TL',
    'TM',
    'TO',
    'TT',
    'TV',
    'TW',
    'UM',
    'US',
    'UY',
    'VA',
    'VC',
    'VG',
    'VI',
    'WF',
    'WS',
    'YT',
];
export var paperCheckCountries = [
    'AD',
    'AE',
    'AF',
    'AG',
    'AI',
    'AL',
    'AM',
    'AO',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AU',
    'AW',
    'AX',
    'AZ',
    'BA',
    'BB',
    'BD',
    'BE',
    'BF',
    'BG',
    'BH',
    'BI',
    'BJ',
    'BL',
    'BM',
    'BN',
    'BO',
    'BQ',
    'BR',
    'BS',
    'BT',
    'BV',
    'BW',
    'BY',
    'BZ',
    'CA',
    'CC',
    'CD',
    'CF',
    'CG',
    'CH',
    'CI',
    'CK',
    'CL',
    'CM',
    'CN',
    'CO',
    'CR',
    'CU',
    'CV',
    'CW',
    'CX',
    'CY',
    'CZ',
    'DE',
    'DJ',
    'DK',
    'DM',
    'DO',
    'DZ',
    'EC',
    'EE',
    'EG',
    'EH',
    'ER',
    'ES',
    'ET',
    'FI',
    'FJ',
    'FK',
    'FM',
    'FO',
    'FR',
    'GA',
    'GB',
    'GD',
    'GE',
    'GF',
    'GG',
    'GH',
    'GI',
    'GL',
    'GM',
    'GN',
    'GP',
    'GQ',
    'GR',
    'GS',
    'GT',
    'GU',
    'GW',
    'GY',
    'HK',
    'HM',
    'HN',
    'HR',
    'HT',
    'HU',
    'ID',
    'IE',
    'IL',
    'IM',
    'IN',
    'IO',
    'IQ',
    'IR',
    'IS',
    'IT',
    'JE',
    'JM',
    'JO',
    'JP',
    'KE',
    'KG',
    'KH',
    'KI',
    'KM',
    'KN',
    'KP',
    'KR',
    'KW',
    'KY',
    'KZ',
    'LA',
    'LB',
    'LC',
    'LI',
    'LK',
    'LR',
    'LS',
    'LT',
    'LU',
    'LV',
    'LY',
    'MA',
    'MC',
    'MD',
    'ME',
    'MF',
    'MG',
    'MH',
    'MK',
    'ML',
    'MM',
    'MN',
    'MO',
    'MP',
    'MQ',
    'MR',
    'MS',
    'MT',
    'MU',
    'MV',
    'MW',
    'MX',
    'MY',
    'MZ',
    'NA',
    'NC',
    'NE',
    'NF',
    'NG',
    'NI',
    'NL',
    'NO',
    'NP',
    'NR',
    'NU',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PF',
    'PG',
    'PH',
    'PK',
    'PL',
    'PM',
    'PN',
    'PS',
    'PT',
    'PW',
    'PY',
    'QA',
    'RE',
    'RO',
    'RS',
    'RU',
    'RW',
    'SA',
    'SB',
    'SC',
    'SD',
    'SE',
    'SG',
    'SH',
    'SI',
    'SJ',
    'SK',
    'SL',
    'SM',
    'SN',
    'SO',
    'SR',
    'SS',
    'ST',
    'SV',
    'SX',
    'SY',
    'SZ',
    'TC',
    'TD',
    'TF',
    'TG',
    'TH',
    'TJ',
    'TK',
    'TL',
    'TM',
    'TN',
    'TO',
    'TR',
    'TT',
    'TV',
    'TW',
    'TZ',
    'UA',
    'UG',
    'US',
    'UY',
    'UZ',
    'VA',
    'VC',
    'VE',
    'VG',
    'VI',
    'VN',
    'VU',
    'WF',
    'WS',
    'YE',
    'YT',
    'ZA',
    'ZM',
    'ZW',
];
