var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { paperCheckCountries, mastercardCountries } from '../../../widget/lists';
import { archerFaqs } from './faq';
import { archerDisclosures } from './disclosures';
import { bankAccountHelp } from './bank-account-help';
var tags = {
    directToBank: 'Direct to your bank account',
    noBank: 'No bank account required'
};
var STRINGS = {
    authEmail: 'By clicking "Submit", you authorize us to send messages to the email address you provided for verification and payment transaction purposes. You must enter the six-digit code contained in the verification message on the next page to receive your payment.',
    authPhone: 'By clicking "Submit", you authorize us to send a message to the phone you provided above for verification purposes. You must enter the six-digit code contained in this message on the next page to receive your payment.',
    authEmailPhone: 'By clicking "Submit", you authorize us to send messages to the email address or phone number you provided for verification and payment transaction purposes. You must enter the six-digit code contained in the verification message on the next page to receive your payment.',
    authBank: 'By clicking "Submit", you authorize us to send payment to the bank account you listed above.',
    authPrePaidEmail: 'By clicking "Submit", you authorize us to send messages to this email address for verification and payment transaction purposes. You must enter the six-digit code contained in the verification message on the next page to confirm your payment selection.',
    authPrePaidWithCheckEmailPhone: 'By clicking "Submit", you authorize us to send messages to this **{{destinationType}}** for verification and payment transaction purposes. You must enter the six-digit code contained in the verification message on the next page to confirm your payment selection.',
    labelEmail: 'Your email',
    labelEmailPhone: 'Email or phone number',
    labelPhoneEmail: 'Phone number or email',
    errorEmailPhone: 'Please enter either a valid email or a valid U.S. phone number',
    errorEmail: 'Please enter a valid email address',
    errorPhone: 'Please enter a valid U.S. phone number',
    promptBank: 'What bank account do you want to use to receive your payment?',
    emailIneligible: 'This email address does not match any of the email addresses in our records.  Please try another email address or contact counsel to update your email address.',
    phoneIneligible: 'This phone number does not match any of the phone numbers in our records.  Please try another phone number or contact counsel to update your phone number.',
    invalidPhoneType: 'The number you entered does not appear to be a valid U.S. mobile number. Please enter a U.S. mobile (i.e., non-landline, non-VOIP, etc.) number.',
    invalidPhoneTypeWithEmail: 'The number you entered does not appear to be a valid U.S. mobile number. Please enter an email address or a U.S. mobile (i.e., non-landline, non-VOIP, etc.) number.',
    emailLimitReached: 'The email address you entered has already been used more than the maximum number of times.',
    phoneLimitReached: 'The phone number you entered has already been used more than the maximum number of times.',
    differentEmailPhone: 'Please enter a different email address or U.S. mobile number',
    differentEmail: 'Please enter a different email address.',
    differentPhone: 'Please enter a different U.S. mobile number',
    withValidationNoConfirmation: ['Your funds should be delivered {{timeFrame}}.'],
    emailUndeliverableEmailOnly: 'Messages sent to the email address you entered appear to be undeliverable. Please enter a valid email address.',
    emailUndeliverableEmailPhone: 'Messages sent to the email address you entered appear to be undeliverable. Please enter a valid email address or a U.S. mobile number.'
};
var SETS = {
    emailCollection: {
        allowedContact: 'EMAIL',
        auth: STRINGS.authEmail,
        label: STRINGS.labelEmail,
        validationError: STRINGS.errorEmail,
        emailIneligible: STRINGS.emailIneligible,
        phoneIneligible: STRINGS.phoneIneligible,
        invalidPhoneType: STRINGS.invalidPhoneType,
        invalidPhoneTypeWithEmail: STRINGS.invalidPhoneTypeWithEmail,
        emailLimitReached: STRINGS.emailLimitReached,
        phoneLimitReached: STRINGS.phoneLimitReached,
        differentEmailPhone: STRINGS.differentEmailPhone,
        differentEmail: STRINGS.differentEmail,
        differentPhone: STRINGS.differentPhone,
        emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly
    },
    emailPhoneCollection: {
        allowedContact: 'BOTH',
        validationError: STRINGS.errorEmailPhone,
        validationPhoneError: STRINGS.errorPhone,
        validationEmailError: STRINGS.errorEmail,
        emailIneligible: STRINGS.emailIneligible,
        phoneIneligible: STRINGS.phoneIneligible,
        invalidPhoneType: STRINGS.invalidPhoneType,
        invalidPhoneTypeWithEmail: STRINGS.invalidPhoneTypeWithEmail,
        emailLimitReached: STRINGS.emailLimitReached,
        phoneLimitReached: STRINGS.phoneLimitReached,
        differentEmailPhone: STRINGS.differentEmailPhone,
        differentEmail: STRINGS.differentEmail,
        differentPhone: STRINGS.differentPhone,
        emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone
    }
};
var archerConfigValues = {
    commonElements: {
        Q: 'Q:',
        A: 'A:',
        faq: 'FAQ',
        disclosure: 'DISCLOSURE',
        buttonNext: 'Submit',
        buttonSubmit: 'Submit',
        buttonConfirm: 'CONFIRM',
        buttonGoBack: 'GO BACK',
        isRequired: 'is required',
        isNotValid: 'is not valid'
    },
    textClaimIdCheck: {
        header: 'Please verify your identity',
        labelId: 'ARCHER PIN',
        labelName: 'Last 4 of SSN',
        prompt: 'Before you select a payment method, we need to verify your identity.  Please enter the information requested below.',
        extraVerificationPrompt: 'Please answer these verification questions:',
        errorNoEdit: 'We have already collected the payment information for this claimant.',
        errorNoId: 'The ARCHER PIN and the last 4 of SSN do not match.',
        errorNoTrackingId: 'We do not recognize the URL you used. Try to enter your ARCHER PIN from your approval email:',
        errorStatus: 'The deadline to change your preferred payment method has passed.',
        errorStatusGeneric: 'Sorry, the payment information cannot be updated at this time.',
        errorFinal: 'We are unable to process your request. Please contact the administrator, or try again later.',
        errorLocked: 'You have input the wrong credentials too many times. Please try again after {{duration}}.'
    },
    paperCheckLink: {
        onlyMethod: 'Unfortunately, no digital payment options are available for your country of residence. Please click this ||link|#/paper-check|| to request a paper check.',
        otherMethods: 'If you prefer to receive a paper check instead of a faster and more convenient option listed above, click this ||link|#/paper-check||.',
        display: 'button',
        textSize: 11
    },
    textSuccess: {
        header: 'Your payment selection has been recorded.',
        button: 'OK',
        noValidation: [
            'Thank you for choosing a payment method. Please close this window and complete the remainder of the Claim Form.',
        ],
        withValidation: ['Your funds should be delivered {{timeFrame}}.'],
        withValidationNoEstimate: ['You will receive an email confirmation of your payment at that time.']
    },
    textConfirmationCode: {
        header: ' ',
        message: 'Enter the 6-digit verification code',
        email: 'the email',
        sms: 'the text message',
        label: 'Sent to **{{destination}}**',
        errorCode: 'The code you have entered is not correct. Please reenter your verification code.',
        button: 'Submit',
        newCodeSent: 'A new payment verification code has been sent.',
        requestNew: 'Did not get a code? ||Resend|#/resend||'
    },
    faqExtraOptions: {
        closeButtonText: 'Close'
    },
    secondaryCollection: {
        allowedContact: 'PHONE',
        label: 'Your phone',
        promptRts: 'We could not validate your email address. What mobile number is associated with your {{method}} account? ',
        promptRtsNoAssoc: 'We could not validate your {{method}} payment selection. What mobile number would you like to use to receive a verification code? ',
        promptRtsNoAssocNonEmail: 'To validate your {{method}} payment selection, please provide your US mobile number and click Submit.',
        promptRtsNoAssocEmail: 'We could not validate your email address. What mobile number would you like to use to receive a verification code?',
        promptRtsNoPhone: 'If you do not yet have a US mobile phone number, please type None and click Submit.',
        promptRtsPaperCheck: 'If you do not have a U.S. mobile number, click ||here|#/return|| to select Paper Check.',
        validationError: STRINGS.errorPhone,
        alternateText: 'We could not validate your payment selection. If your claim is approved, we will send you a virtual Mastercard or a paper check.',
        paperCheckText: 'Your payment selection was recorded but was not able to be validated. If your claim is approved, you will receive a paper check.',
        rejectedText: 'Your payment selection was recorded but was not able to be validated.',
        auth: STRINGS.authPrePaidWithCheckEmailPhone,
        phoneIneligible: STRINGS.phoneIneligible,
        invalidPhoneType: STRINGS.invalidPhoneType,
        invalidPhoneTypeWithEmail: STRINGS.invalidPhoneTypeWithEmail,
        phoneLimitReached: STRINGS.phoneLimitReached,
        differentPhone: STRINGS.differentPhone,
        emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly,
        emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone
    },
    pMasterCardCollection: {
        title: 'Physical Mastercard',
        prompt: 'What address do you want to use to receive your Prepaid Mastercard Payment?',
        auth: 'By clicking "Submit", you authorize us or our partners to mail the Prepaid Mastercard to the address provided above.',
        allowedCountries: mastercardCountries,
        withValidation: STRINGS.withValidationNoConfirmation
    },
    vMasterCardCollection: __assign(__assign({ title: 'Virtual Mastercard', header: ' ', prompt: 'What email address do you want to use for the delivery of your Prepaid Mastercard payment?' }, SETS.emailCollection), { auth: STRINGS.authEmailPhone, emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    achCollection: {
        title: 'Direct Deposit',
        prompt: STRINGS.promptBank,
        auth: STRINGS.authBank,
        labelRouting: 'Routing number',
        labelAccount: 'Account number',
        labelAccountCopy: 'Re enter Account Number',
        labelChecking: 'Checking',
        labelSavings: 'Savings',
        errorRouting: 'The routing number should have exactly 9 digits',
        errorRoutingChecksum: 'The routing number is not valid',
        errorAccount: 'Please enter a valid account number',
        errorAccountCopy: 'Account number does not match',
        withValidation: STRINGS.withValidationNoConfirmation
    },
    paypalCollection: __assign(__assign({ title: 'PayPal', header: ' ', prompt: 'What email address or mobile number do you want to use to receive your PayPal Payment?', auth: STRINGS.authEmailPhone, label: STRINGS.labelEmailPhone }, SETS.emailPhoneCollection), { emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    venmoCollection: __assign(__assign({ title: 'Venmo', header: ' ', prompt: 'What email address or mobile number do you want to use to receive your Venmo Payment?', auth: STRINGS.authEmailPhone, confirmation: 'Please confirm that you understand and agree that the notification of your Venmo settlement payment will be visible to others unless your default privacy setting is Private at the time of distribution.', confirmationTitle: 'Privacy Notice', label: STRINGS.labelPhoneEmail }, SETS.emailPhoneCollection), { emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    amazonCollection: __assign(__assign({ title: 'Amazon', header: ' ', prompt: 'What email address do you want to use to receive your Amazon Gift Card Payment?' }, SETS.emailCollection), { auth: STRINGS.authPrePaidEmail, emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    starbucksCollection: __assign(__assign({ title: 'Starbucks', header: ' ', prompt: 'What email address do you want to use to receive your Starbucks Gift Card Payment?' }, SETS.emailCollection), { auth: STRINGS.authPrePaidEmail, emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    lowesCollection: __assign(__assign({ title: "Lowe's", header: ' ', prompt: 'What email address do you want to use to receive your Lowe’s Gift Card Payment?' }, SETS.emailCollection), { auth: STRINGS.authPrePaidEmail, emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    bitcoinCollection: __assign(__assign({ title: 'Bitcoin', header: ' ', prompt: 'What email address do you want to use to receive instructions regarding your Bitcoin payment?' }, SETS.emailCollection), { auth: STRINGS.authEmail, emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    interacCollection: __assign(__assign({ title: 'Interac', header: ' ', prompt: 'What email address do you want to use for the delivery of your Interac e-Transfer payment?' }, SETS.emailCollection), { auth: STRINGS.authEmail, withValidation: STRINGS.withValidationNoConfirmation, emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    paperCheckCollection: {
        title: 'Paper Check',
        prompt: 'Where should this payment be sent?',
        auth: 'By clicking "Submit", you authorize us to send your payment to the address provided above.',
        allowedCountries: paperCheckCountries,
        withValidation: STRINGS.withValidationNoConfirmation
    },
    targetCollection: __assign(__assign({}, SETS.emailCollection), { title: 'Target', header: ' ', prompt: 'What email address do you want to use to receive your Target Gift Card Payment?', auth: STRINGS.authPrePaidEmail, emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    zelleCollection: __assign(__assign({ title: 'Zelle', header: ' ', prompt: 'What email address or mobile number do you want to use to receive your Zelle Payment?', auth: STRINGS.authEmailPhone, label: STRINGS.labelEmailPhone }, SETS.emailPhoneCollection), { emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    eftCollection: {
        title: 'Direct Deposit',
        prompt: STRINGS.promptBank,
        auth: STRINGS.authBank,
        labelRouting: 'Routing number (XXXXX-XXX)',
        labelAccount: 'Account number',
        labelAccountCopy: 'Re-enter account number',
        labelChecking: 'Checking',
        labelSavings: 'Savings',
        errorRouting: 'The routing number is not valid',
        errorRoutingChecksum: 'The routing number is not valid',
        errorAccount: 'Please enter a valid account number',
        errorAccountCopy: 'Account number does not match',
        allowAlternateRouting: true,
        disableRoutingChecksum: true
    },
    layout: {
        buttonSize: 'auto',
        sequence: [
            'E-MasterCard',
            'Amazon',
            'IAT',
            'PayPal',
            'Venmo',
            'ACH',
            'Zelle',
            'Interac',
            'Target',
            'Starbucks',
            'Lowes',
            'Wire',
            'Paper Check',
            'Physical MasterCard',
            'Bitcoin',
            'Offline',
            'EFT Canada',
        ],
        legend: 'Payment Options',
        completed: 'You have successfully submitted your payment details.',
        noTracking: 'To access the payment selection process, please click on the link from the email you received.'
    },
    paymentButtons: {
        ACH: {
            logoType: 'image',
            image: 'ach',
            buttonText: 'USE DIRECT DEPOSIT',
            tagLines: [tags.directToBank, 'Get payment in 1-3 business days'],
            estimatedDelivery: '1-3 business days',
            displayName: 'Direct Deposit'
        },
        Amazon: {
            logoType: 'image',
            image: 'amazon',
            buttonText: 'GET AN AMAZON CREDIT',
            tagLines: 'Use for your Amazon purchases',
            estimatedDelivery: 'today (within hours)'
        },
        Bitcoin: {
            logoType: 'image',
            image: 'bitcoin',
            buttonText: 'GET BITCOIN',
            tagLines: ['Largest cryptocurrency', '(by market capitalization)'],
            estimatedDelivery: '1-2 weeks'
        },
        'E-MasterCard': {
            image: 'virtual_mc',
            tagLines: [tags.noBank, 'Get payment today (within hours)'],
            buttonText: 'GET A PREPAID MASTERCARD',
            logoType: 'image',
            estimatedDelivery: 'today (within hours)',
            displayName: 'E-Mastercard'
        },
        'Physical MasterCard': {
            image: 'virtual_mc',
            tagLines: 'Most widely accepted prepaid card',
            buttonText: 'GET A PREPAID MASTERCARD',
            logoType: 'image',
            estimatedDelivery: '1-2 weeks',
            displayName: 'Physical Mastercard'
        },
        IAT: {
            logoType: 'image',
            image: 'ach',
            buttonText: 'USE DIRECT DEPOSIT',
            tagLines: tags.directToBank,
            estimatedDelivery: '1-2 weeks'
        },
        Interac: {
            logoType: 'image',
            image: 'interac',
            buttonText: 'USE INTERAC',
            tagLines: tags.directToBank,
            estimatedDelivery: '1-2 weeks'
        },
        'Paper Check': {
            logoType: 'text',
            logo: 'Paper Check By Mail',
            buttonText: 'USE PAPER CHECK',
            tagLines: 'Get payment in 30-45 days',
            logoColor: '#777777',
            estimatedDelivery: '30-45 days'
        },
        PayPal: {
            logoType: 'image',
            image: 'paypal',
            buttonText: 'USE PAYPAL',
            tagLines: [tags.noBank, 'Get payment in 1-3 business days'],
            estimatedDelivery: '1-3 business days'
        },
        Target: {
            logoType: 'image',
            image: 'target2',
            buttonText: 'GET A TARGET CARD',
            tagLines: 'Use for your Target purchases',
            estimatedDelivery: 'today (within hours)'
        },
        Starbucks: {
            logoType: 'image',
            image: 'starbucks',
            buttonText: 'GET A STARBUCKS CARD',
            tagLines: 'Use for your Starbucks purchases',
            estimatedDelivery: 'today (within hours)'
        },
        Lowes: {
            logoType: 'image',
            image: 'lowes',
            buttonText: "GET A LOWE'S CARD",
            tagLines: "Use for your Lowe's purchases",
            estimatedDelivery: 'today (within hours)',
            displayName: "Lowe's"
        },
        Venmo: {
            logoType: 'image',
            image: 'venmo',
            buttonText: 'USE VENMO',
            tagLines: [tags.noBank, 'Get payment in 1-3 business days'],
            estimatedDelivery: '1-3 business days'
        },
        Wire: {
            logoType: 'text',
            logo: 'Wire (PLACEHOLDER)',
            logoColor: '#777777',
            buttonText: 'USE WIRE',
            tagLines: 'Direct to your bank account',
            estimatedDelivery: '1-2 weeks'
        },
        Zelle: {
            logoType: 'image',
            image: 'zelle',
            buttonText: 'USE ZELLE',
            tagLines: [tags.directToBank, 'Get payment in 1-3 business days'],
            estimatedDelivery: '1-3 business days'
        },
        Offline: {
            logoType: 'text',
            logo: 'eCheck',
            logoColor: '#777777',
            tagLines: ['Sent to your email address'],
            buttonText: 'USE EMAIL CHECK',
            estimatedDelivery: 'today (within hours)',
            displayName: 'eCheck'
        },
        'EFT Canada': {
            logoType: 'image',
            image: 'eft',
            buttonText: 'USE DIRECT DEPOSIT',
            tagLines: tags.directToBank,
            estimatedDelivery: '2-5 business days',
            displayName: 'Direct Deposit'
        }
    }
};
export var archerConfig = __assign(__assign(__assign(__assign({}, archerConfigValues), archerFaqs), archerDisclosures), { bankAccountHelp: bankAccountHelp });
