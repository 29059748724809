export var vMasterCardFaq = {
    title: 'Prepaid Mastercard® Frequently Asked Questions',
    questions: {
        b: {
            Q: 'How do I activate my Virtual Prepaid Mastercard?',
            A: 'You can activate your Virtual Prepaid Mastercard by clicking on the link in your notification email.'
        },
        c: {
            Q: 'How do I use my Virtual Prepaid Mastercard?',
            A: [
                'A: To make purchases with your Virtual Prepaid Mastercard, please do one of the following:',
                {
                    type: 'list',
                    items: [
                        'Use the 16-digit card number and make an online purchase (from any online merchant that accepts Mastercard) OR',
                        'Add the virtual Mastercard to a mobile wallet (ApplePay, Samsung Pay, etc.) and spend the card in-store at any merchant that accepts mobile wallet transactions (and accepts Mastercard)',
                        'Also, some in-store merchants will allow you to present an image of the virtual Mastercard and take note of your 16-digit card number, expiration date, and the 3-4 digit CVV code.',
                    ]
                },
            ]
        },
        d: {
            Q: 'Will I get a physical card?',
            A: 'For U.S. residents, you can request a physical card for a fee.'
        },
        e: {
            Q: 'Can I transfer funds to my bank account?',
            A: 'You can transfer your remaining card balance to your bank account.'
        }
    }
};
