var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultConfig } from '../default-config';
var config = defaultConfig();
export function createFrame(url, style) {
    var defaultStyles = __assign({ border: 'none', width: '0px', height: '0px', overflow: 'hidden' }, style);
    var frame = document.createElement('iframe');
    frame.frameBorder = '0';
    frame.title = config.accessibility.iframeTitle || 'Payment Form';
    frame.style.border = defaultStyles.border;
    frame.style.width = defaultStyles.width;
    frame.style.height = defaultStyles.height;
    frame.style.overflow = defaultStyles.overflow;
    frame.src = process.env.BASE_URL + url;
    return frame;
}
