export var targetFaq = {
    title: 'Target Gift Card Frequently Asked Questions',
    questions: {
        a: {
            Q: 'What is a Target eGiftCard?',
            A: 'A Target eGiftCard allows you to use the card balance to shop online at www.target.com or in person at a Target store.'
        },
        b: {
            Q: 'What information is required to choose the Target eGiftCard payment option?',
            A: 'Email address.'
        },
        c: {
            Q: 'How do I use a Target eGiftCard?',
            A: 'You can apply the funds online at the time of checkout or in person by scanning a barcode on your phone at the time of checkout.'
        },
        d: {
            Q: 'Where can I find more information regarding Target eGiftCards?',
            A: 'Please visit ||https://www.target.com/c/target-giftcards/-/N-5xsxt||.'
        }
    }
};
