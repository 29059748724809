var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { paperCheckCountries, mastercardCountries } from '../../../widget/lists';
import { epiqFaqs } from './faq';
import { epiqDisclosures } from './disclosures';
var STRINGS = {
    authEmail: 'By clicking "Submit", you authorize us to send messages to this email address for verification and payment transaction purposes, and you agree to the ||E-signature Disclosure|/agreements/esign-disclosure/index.html||, ||Privacy Policy|/agreements/privacy-policy/index.html||, and ||Terms of Service|/agreements/terms-of-service/index.html||. Enter the six-digit code contained in the verification message on the next page to confirm your payment selection.',
    authPhone: 'By clicking "Submit", you authorize us to send messages to this phone number for verification and payment transaction purposes, and you agree to the ||E-signature Disclosure|/agreements/esign-disclosure/index.html||, ||Privacy Policy|/agreements/privacy-policy/index.html||, and ||Terms of Service|/agreements/terms-of-service/index.html||. Enter the six-digit code contained in the verification message on the next page to confirm your payment selection.',
    authEmailPhone: 'By clicking "Submit", you authorize us to send messages to this **{{destinationType}}** for verification and payment transaction purposes, and you agree to the ||E-signature Disclosure|/agreements/esign-disclosure/index.html||, ||Privacy Policy|/agreements/privacy-policy/index.html||, and ||Terms of Service|/agreements/terms-of-service/index.html||. Enter the six-digit code contained in the verification message on the next page to confirm your payment selection.',
    authBank: 'By clicking "Submit", you authorize us to send your payment to the bank account provided above, and you agree to the ||E-signature Disclosure|/agreements/esign-disclosure/index.html||, ||Privacy Policy|/agreements/privacy-policy/index.html||, and ||Terms of Service|/agreements/terms-of-service/index.html||.',
    authPrePaidEmail: 'By clicking "Submit", you authorize us to send messages to this email address for verification and payment transaction purposes, and you agree to the ||E-signature Disclosure|/agreements/esign-disclosure/index.html||, ||Privacy Policy|/agreements/privacy-policy/index.html||, ||Terms of Service|/agreements/terms-of-service/index.html||, and to receive the balance of your payment in the form of a virtual prepaid Mastercard if your payment is unable to be delivered by your selected method. Enter the six-digit code contained in the verification message on the next page to confirm your payment selection.',
    authPrePaidWithCheckEmailPhone: 'By clicking "Submit", you authorize us to send messages to this **{{destinationType}}** for verification and payment transaction purposes, and you agree to the ||E-signature Disclosure|/agreements/esign-disclosure/index.html||, ||Privacy Policy|/agreements/privacy-policy/index.html||, ||Terms of Service|/agreements/terms-of-service/index.html||, and to receive the balance of your payment in the form of a virtual prepaid Mastercard to the email address in our records or by paper check if your payment is unable to be delivered by your selected method. Enter the six-digit code contained in the verification message on the next page to confirm your payment selection.',
    labelEmail: 'Your email',
    labelEmailPhone: 'Email or phone number',
    labelPhoneEmail: 'Phone number or email',
    errorEmailPhone: 'Please enter either a valid email or a valid U.S. phone number',
    errorEmail: 'Please enter a valid email address',
    errorPhone: 'Please enter a valid U.S. phone number',
    promptBank: 'What bank account do you want to use to receive your payment?',
    emailIneligible: 'This email address does not match any of the email addresses in our records.  Please try another email address or contact counsel to update your email address.',
    phoneIneligible: 'This phone number does not match any of the phone numbers in our records.  Please try another phone number or contact counsel to update your phone number.',
    invalidPhoneType: 'The number you entered does not appear to be a valid U.S. mobile number. Please enter a U.S. mobile (i.e., non-landline, non-VOIP, etc.) number.',
    invalidPhoneTypeWithEmail: 'The number you entered does not appear to be a valid U.S. mobile number. Please enter an email address or a U.S. mobile (i.e., non-landline, non-VOIP, etc.) number.',
    emailLimitReached: 'The email address you entered has already been used more than the maximum number of times.',
    phoneLimitReached: 'The phone number you entered has already been used more than the maximum number of times.',
    differentEmailPhone: 'Please enter a different email address or U.S. mobile number',
    differentEmail: 'Please enter a different email address.',
    differentPhone: 'Please enter a different U.S. mobile number',
    withValidationNoConfirmation: ['Your funds should be delivered **{{timeFrame}}**.'],
    emailUndeliverableEmailOnly: 'Messages sent to the email address you entered appear to be undeliverable. Please enter a valid email address.',
    emailUndeliverableEmailPhone: 'Messages sent to the email address you entered appear to be undeliverable. Please enter a valid email address or a U.S. mobile number.'
};
var SETS = {
    emailCollection: {
        allowedContact: 'EMAIL',
        auth: STRINGS.authEmail,
        label: STRINGS.labelEmail,
        validationError: STRINGS.errorEmail,
        emailIneligible: STRINGS.emailIneligible,
        phoneIneligible: STRINGS.phoneIneligible,
        invalidPhoneType: STRINGS.invalidPhoneType,
        invalidPhoneTypeWithEmail: STRINGS.invalidPhoneTypeWithEmail,
        emailLimitReached: STRINGS.emailLimitReached,
        phoneLimitReached: STRINGS.phoneLimitReached,
        differentEmailPhone: STRINGS.differentEmailPhone,
        differentEmail: STRINGS.differentEmail,
        differentPhone: STRINGS.differentPhone,
        emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly
    },
    emailPhoneCollection: {
        allowedContact: 'BOTH',
        validationError: STRINGS.errorEmailPhone,
        validationPhoneError: STRINGS.errorPhone,
        validationEmailError: STRINGS.errorEmail,
        emailIneligible: STRINGS.emailIneligible,
        phoneIneligible: STRINGS.phoneIneligible,
        invalidPhoneType: STRINGS.invalidPhoneType,
        invalidPhoneTypeWithEmail: STRINGS.invalidPhoneTypeWithEmail,
        emailLimitReached: STRINGS.emailLimitReached,
        phoneLimitReached: STRINGS.phoneLimitReached,
        differentEmailPhone: STRINGS.differentEmailPhone,
        differentEmail: STRINGS.differentEmail,
        differentPhone: STRINGS.differentPhone,
        emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone
    }
};
var epiqConfigValues = {
    paperCheckLink: {
        onlyMethod: 'Unfortunately, no digital payment options are available for your country of residence. Please click this ||link|#/paper-check|| to request a paper check.',
        otherMethods: 'If you prefer to receive a paper check instead of a faster and more convenient option listed above, click this ||link|#/paper-check||.',
        display: 'button',
        textSize: 11
    },
    textSuccess: {
        header: 'Your payment selection has been recorded!',
        button: 'Close',
        noValidation: [
            'Thank you for choosing a payment method. Please close this window and complete the remainder of the Claim Form.',
        ],
        withValidation: [
            'Your funds should be delivered **{{timeFrame}}**. You will receive an email confirmation of your payment at that time.',
        ],
        withValidationNoEstimate: ['You will receive an email confirmation of your payment at that time.']
    },
    textConfirmationCode: {
        header: 'Step 2 of 2',
        message: 'Enter the 6-digit verification code sent to **{{destination}}** and click Submit to confirm your payment selection.',
        email: 'the email',
        sms: 'the text message',
        label: 'Verification code',
        errorCode: 'The code you have entered is not correct. Please reenter your verification code.',
        button: 'Submit',
        newCodeSent: 'A new payment verification code has been sent.',
        requestNew: "If you didn't receive the code, please ||click here|#/resend|| to request a new one."
    },
    faqExtraOptions: {
        closeButtonText: 'Close'
    },
    secondaryCollection: {
        allowedContact: 'PHONE',
        label: 'Your phone',
        promptRts: 'We could not validate your email address. What mobile number is associated with your {{method}} account? ',
        promptRtsNoAssoc: 'We could not validate your {{method}} payment selection. What mobile number would you like to use to receive a verification code? ',
        promptRtsNoAssocNonEmail: 'To validate your {{method}} payment selection, please provide your US mobile number and click Submit.',
        promptRtsNoAssocEmail: 'We could not validate your email address. What mobile number would you like to use to receive a verification code?',
        promptRtsNoPhone: 'If you do not yet have a US mobile phone number, please type None and click Submit.',
        promptRtsPaperCheck: 'If you do not have a U.S. mobile number, click ||here|#/return|| to select Paper Check.',
        validationError: STRINGS.errorPhone,
        alternateText: 'We could not validate your payment selection. If your claim is approved, we will send you a virtual Mastercard or a paper check.',
        paperCheckText: 'Your payment selection was recorded but was not able to be validated. If your claim is approved, you will receive a paper check.',
        rejectedText: 'Your payment selection was recorded but was not able to be validated.',
        auth: STRINGS.authPrePaidWithCheckEmailPhone,
        phoneIneligible: STRINGS.phoneIneligible,
        invalidPhoneType: STRINGS.invalidPhoneType,
        invalidPhoneTypeWithEmail: STRINGS.invalidPhoneTypeWithEmail,
        phoneLimitReached: STRINGS.phoneLimitReached,
        differentPhone: STRINGS.differentPhone,
        emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly,
        emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone
    },
    pMasterCardCollection: {
        title: 'Physical Mastercard Payment Details',
        prompt: 'What address do you want to use to receive your Prepaid Mastercard Payment?',
        auth: 'By clicking "Submit", you authorize us or our partners to mail the Prepaid Mastercard to the address provided above, and you agree to the ||E-signature Disclosure|/agreements/esign-disclosure/index.html||, ||Privacy Policy|/agreements/privacy-policy/index.html||, and ||Terms of Service|/agreements/terms-of-service/index.html||.',
        allowedCountries: mastercardCountries,
        withValidation: STRINGS.withValidationNoConfirmation
    },
    vMasterCardCollection: __assign(__assign({ title: 'Virtual Mastercard Payment Details', header: 'Step 1 of 2', prompt: 'What email address do you want to use for the delivery of your Prepaid Mastercard payment?' }, SETS.emailCollection), { auth: STRINGS.authEmailPhone, emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    achCollection: {
        title: 'Bank Deposit',
        prompt: STRINGS.promptBank,
        auth: STRINGS.authBank,
        labelRouting: 'Routing number',
        labelAccount: 'Account number',
        labelAccountCopy: 'Re enter Account Number',
        labelChecking: 'Checking',
        labelSavings: 'Savings',
        errorRouting: 'The routing number should have exactly 9 digits',
        errorRoutingChecksum: 'The routing number is not valid',
        errorAccount: 'Please enter a valid account number',
        errorAccountCopy: 'Account number does not match',
        withValidation: STRINGS.withValidationNoConfirmation
    },
    paypalCollection: __assign(__assign({ title: 'PayPal Payment Details', header: 'Step 1 of 2', prompt: 'What email address or mobile number do you want to use to receive your PayPal Payment?', auth: STRINGS.authEmailPhone, label: STRINGS.labelEmailPhone }, SETS.emailPhoneCollection), { emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    venmoCollection: __assign(__assign({ title: 'Venmo Payment Details', header: 'Step 1 of 2', prompt: 'What email address or mobile number do you want to use to receive your Venmo Payment?', auth: STRINGS.authEmailPhone, confirmation: 'Please confirm that you understand and agree that the notification of your Venmo settlement payment will be visible to others unless your default privacy setting is Private at the time of distribution.', confirmationTitle: 'Privacy Notice', label: STRINGS.labelPhoneEmail }, SETS.emailPhoneCollection), { emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    amazonCollection: __assign(__assign({ title: 'Amazon Payment Details', header: 'Step 1 of 2', prompt: 'What email address do you want to use to receive your Amazon Gift Card Payment?' }, SETS.emailCollection), { auth: STRINGS.authPrePaidEmail, emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    starbucksCollection: __assign(__assign({ title: 'Starbucks Payment Details', header: 'Step 1 of 2', prompt: 'What email address do you want to use to receive your Starbucks Gift Card Payment?' }, SETS.emailCollection), { auth: STRINGS.authPrePaidEmail, emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    lowesCollection: __assign(__assign({ title: "Lowe's Payment Details", header: 'Step 1 of 2', prompt: 'What email address do you want to use to receive your Lowe’s Gift Card Payment?' }, SETS.emailCollection), { auth: STRINGS.authPrePaidEmail, emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    bitcoinCollection: __assign(__assign({ title: 'Bitcoin Payment Details', header: 'Step 1 of 2', prompt: 'What email address do you want to use to receive instructions regarding your Bitcoin payment?' }, SETS.emailCollection), { auth: STRINGS.authEmail, emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    interacCollection: __assign(__assign({ title: 'Interac Payment Details', header: 'Step 1 of 2', prompt: 'What email address do you want to use for the delivery of your Interac e-Transfer payment?' }, SETS.emailCollection), { auth: STRINGS.authEmail, withValidation: STRINGS.withValidationNoConfirmation, emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    paperCheckCollection: {
        title: 'Paper Check Payment Details',
        prompt: 'What address do you want to use to receive your payment?',
        auth: 'By clicking "Submit", you authorize us to send your payment to the address provided above, and you agree to the ||E-signature Disclosure|/agreements/esign-disclosure/index.html||, ||Privacy Policy|/agreements/privacy-policy/index.html||, and ||Terms of Service|/agreements/terms-of-service/index.html||.',
        allowedCountries: paperCheckCountries,
        withValidation: STRINGS.withValidationNoConfirmation
    },
    targetCollection: __assign(__assign({}, SETS.emailCollection), { title: 'Target Payment Details', header: 'Step 1 of 2', prompt: 'What email address do you want to use to receive your Target Gift Card Payment?', auth: STRINGS.authPrePaidEmail, emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    zelleCollection: __assign(__assign({ title: 'Zelle Payment Details', header: 'Step 1 of 2', prompt: 'What email address or mobile number do you want to use to receive your Zelle Payment?', auth: STRINGS.authEmailPhone, label: STRINGS.labelEmailPhone }, SETS.emailPhoneCollection), { emailUndeliverableEmailOnly: STRINGS.emailUndeliverableEmailOnly, emailUndeliverableEmailPhone: STRINGS.emailUndeliverableEmailPhone }),
    eftCollection: {
        title: 'Direct Deposit',
        prompt: STRINGS.promptBank,
        auth: STRINGS.authBank,
        labelRouting: 'Routing number (XXXXX-XXX)',
        labelAccount: 'Account number',
        labelAccountCopy: 'Re-enter account number',
        labelChecking: 'Checking',
        labelSavings: 'Savings',
        errorRouting: 'The routing number is not valid',
        errorRoutingChecksum: 'The routing number is not valid',
        errorAccount: 'Please enter a valid account number',
        errorAccountCopy: 'Account number does not match',
        allowAlternateRouting: true,
        disableRoutingChecksum: true
    }
};
export var epiqConfig = __assign(__assign(__assign({}, epiqConfigValues), epiqFaqs), epiqDisclosures);
