export var defaultDisclosures = {
    amazonDisclosure: {
        title: 'Amazon eGiftCard Disclosure and FAQs',
        rows: {
            a: [
                'Amazon.com is not a sponsor of this promotion. Except as required by law, Amazon.com Gift Cards ("GCs") cannot be transferred for value or redeemed for cash. GCs may be used only for purchases of eligible goods on Amazon.com or certain of its affiliated websites. GCs cannot be redeemed for purchases of gift cards. Purchases are deducted from the GC balance. To redeem or view a GC balance, visit "Your Account" on Amazon.com. Amazon is not responsible if a GC is lost, stolen, destroyed or used without permission. For complete terms and conditions, see ||www.amazon.com/gc-legal|https://www.amazon.com/gc-legal||. GCs are issued by ACI Gift Cards, Inc., a Washington corporation. All Amazon ™ & © are IP of Amazon.com, Inc. or its affiliates. No expiration date or service fees.',
            ]
        }
    },
    targetDisclosure: {
        title: 'Target Gift Card Disclosure and FAQs',
        rows: {
            a: [
                'Redeemable for merchandise or services (other than gift cards and prepaid cards) at Target stores in the U.S. or ||Target.com|http://target.com|| and cannot be redeemed for cash or credit except where required by law. For balance information, visit ||Target.com/checkbalance|http://Target.com/checkbalance|| or call 1-800-544-2943. To replace the remaining value on a lost, stolen or damaged card with the original purchase receipt, call 1-800-544-2943.  ©2023 Target Brands, Inc. The Bullseye Design and Target are registered trademarks of Target Brands, Inc.  Target is not a participating partner in or sponsor of this offer.',
            ]
        }
    },
    starbucksDisclosure: {
        title: 'Starbucks Gift Card Disclosure and FAQs',
        rows: {
            a: [
                'Treat this Card like cash. Reload your Card, check your balance and find out how to register and protect your Card balance at participating stores, Starbucks.com/card or 1-800-782-7282. Cannot be redeemed for cash unless required by law. Refunds only provided for unused Cards with the original receipt. This Card does not expire, nor does Starbucks charge fees. Complete terms and conditions available on our website. Use of this Card constitutes acceptance of these terms and conditions.',
            ]
        }
    },
    lowesDisclosure: {
        title: "Lowe's Gift Card Disclosure and FAQs",
        rows: {
            a: [
                'This is a Lowe’s Gift Card and is valid in US only.  This is not a credit/debit card and has no implied warranties.  This card is not redeemable for cash unless required by law and cannot be used to make payments on any charge account or used to purchase any other retailer or third-party gift card.  Lowe’s reserves the right to deactivate or reject any Gift Card issued or procured through fraudulent activity.  Lost or stolen Gift Cards can only be replaced upon presentation of original sales receipt for any remaining balance.  It will be void if altered or defaced.  To check your Lowe’s Gift Card balance: visit ||Lowes.com/GiftCards|https://Lowes.com/GiftCards||; call 1-800-560-7172 or see Customer Service Desk at any Lowe’s store.',
            ]
        }
    }
};
