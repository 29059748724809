export var venmoFaq = {
    title: 'Venmo Frequently Asked Questions',
    questions: {
        a: {
            Q: 'What information is required to choose the Venmo payment option?',
            A: 'Email address or phone number.'
        },
        b: {
            Q: 'Where do the funds go?',
            A: 'Directly to your Venmo account.'
        },
        c: {
            Q: 'Can I transfer funds to my bank account?',
            A: 'Yes. Once the funds are deposited into your Venmo account, you can move the money to your bank account using the normal Venmo transfer process.'
        },
        d: {
            Q: 'Can I choose a Venmo payment if I do not yet have a Venmo account?',
            A: 'Yes.  Your payment notification will include a link to create a Venmo account and receive your payment.'
        }
    }
};
