export var bitcoinFaq = {
    title: 'Bitcoin FAQs',
    questions: {
        a: {
            Q: 'I do not have a Bitcoin wallet.  Can I still choose Bitcoin?',
            A: 'Yes. If you do not have a Bitcoin wallet, you will receive a link to create a Bitcoin wallet.'
        },
        b: {
            Q: 'I already have a Bitcoin wallet.  Can I use that wallet?',
            A: 'Yes.'
        },
        c: {
            Q: 'What is Bitcoin?',
            A: 'As defined in a whitepaper released by the assumed inventor of Bitcoin, Satoshi Nakamoto, Bitcoin is "a purely peer-to-peer version of electronic cash that would allow online payments to be sent directly from one party to another without going through a financial institution."'
        },
        d: {
            Q: 'Is the price of Bitcoin volatile?',
            A: 'Yes. The price of Bitcoin can rapidly change (lower or higher).  Do not store money in Bitcoin that you cannot afford to lose.'
        }
    }
};
