export var zelleFaq = {
    title: 'Zelle Frequently Asked Questions',
    questions: {
        a: {
            Q: 'What is Zelle?',
            A: 'Zelle® is a fast, safe, easy and contact-free way to send and receive money with friends, family and others you trust. Visit ||Zelle|https://www.zellepay.com/|| for more details.'
        },
        b: {
            Q: 'What information is required to choose the Zelle payment option?',
            A: 'Cell phone number or email address.'
        },
        c: {
            Q: 'Where do the funds go?',
            A: 'Directly to your bank account.'
        },
        d: {
            Q: 'Can I choose a Zelle payment if I not yet enrolled with Zelle?',
            A: 'Yes. Your payment notification email will include a link to enroll and receive your payment.'
        }
    }
};
