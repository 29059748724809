export var pMasterCardFaq = {
    title: 'Disbursements Prepaid Mastercard® Physical Card Fee Disclosure and FAQs',
    questions: {
        b: {
            Q: 'How to activate and use my Physical Prepaid Mastercard?',
            A: 'You can activate your Physical Prepaid Mastercard by following the instructions provided in the envelope with your Physical Prepaid Mastercard. Your Physical Prepaid Mastercard can be swiped or used online wherever Mastercard is accepted.'
        },
        c: {
            Q: 'Can I transfer my funds from my Physical Prepaid Mastercard?',
            A: 'You can transfer the remaining card balance to an alternative payment method.'
        }
    }
};
