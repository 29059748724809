/* eslint no-console: 0 */
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var container = window;
var logLevels = ['fatal', 'error', 'warning', 'info', 'debug'];
var logFuncs = [console.error, console.error, console.warn, console.warn, console.warn];
function createLogFunction(category, logLevel) {
    var logLevelIndex = logLevels.indexOf(logLevel);
    var logFunc = logFuncs[logLevelIndex];
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var defaultLogLevel = logLevels.indexOf((container === null || container === void 0 ? void 0 : container.__dstWidgetLogLevel) || process.env.LOG_LEVEL || 'error');
        if (logLevelIndex <= defaultLogLevel && logFunc) {
            var date = new Date();
            logFunc.apply(void 0, __spreadArray(["[".concat(date.toISOString().substring(11, 23), "] [").concat(logLevel, "]"), category ? "[".concat(category, "]") : ''], args, false));
        }
    };
}
export function createLogger(category) {
    return Object.freeze({
        debug: __DEBUG
            ? createLogFunction(category, 'debug')
            : function () {
                /* */
            },
        info: createLogFunction(category, 'info'),
        warning: createLogFunction(category, 'warning'),
        error: createLogFunction(category, 'error'),
        fatal: createLogFunction(category, 'fatal')
    });
}
export var defaultLog = createLogger('');
