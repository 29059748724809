import { achFaq } from './ach';
import { amazonFaq } from './amazon';
import { bitcoinFaq } from './bitcoin';
import { interacFaq } from './interac';
import { lowesFaq } from './lowes';
import { paperCheckFaq } from './papercheck';
import { paypalFaq } from './paypal';
import { pMasterCardFaq } from './physical-mastercard';
import { starbucksFaq } from './starbucks';
import { targetFaq } from './target';
import { venmoFaq } from './venmo';
import { vMasterCardFaq } from './virtual-mastercard';
import { zelleFaq } from './zelle';
export var epiqFaqs = {
    achFaq: achFaq,
    amazonFaq: amazonFaq,
    bitcoinFaq: bitcoinFaq,
    interacFaq: interacFaq,
    lowesFaq: lowesFaq,
    paperCheckFaq: paperCheckFaq,
    paypalFaq: paypalFaq,
    pMasterCardFaq: pMasterCardFaq,
    starbucksFaq: starbucksFaq,
    targetFaq: targetFaq,
    venmoFaq: venmoFaq,
    vMasterCardFaq: vMasterCardFaq,
    zelleFaq: zelleFaq
};
