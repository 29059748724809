var tags = {
    directToBank: 'Direct to your bank account',
    noBank: 'No bank account required'
};
export var paymentButtons = {
    ACH: {
        logoType: 'image',
        image: 'ach',
        buttonText: 'USE DIRECT DEPOSIT',
        tagLines: tags.directToBank,
        estimatedDelivery: '2-5 business days',
        displayName: 'Direct Deposit'
    },
    Amazon: {
        logoType: 'image',
        image: 'amazon',
        buttonText: 'GET AN AMAZON CREDIT',
        tagLines: 'Use for your Amazon purchases',
        estimatedDelivery: 'Within hours'
    },
    Bitcoin: {
        logoType: 'image',
        image: 'bitcoin',
        buttonText: 'GET BITCOIN',
        tagLines: ['Largest cryptocurrency', '(by market capitalization)'],
        estimatedDelivery: '1-2 weeks'
    },
    'E-MasterCard': {
        image: 'virtual_mc3',
        tagLines: ['Most widely accepted virtual prepaid card', 'Use with Apple Pay, Google Pay, Samsung Pay'],
        buttonText: 'GET A PREPAID MASTERCARD',
        logoType: 'image',
        estimatedDelivery: 'Within hours',
        displayName: 'E-Mastercard'
    },
    'Physical MasterCard': {
        image: 'virtual_mc3',
        tagLines: 'Most widely accepted prepaid card',
        buttonText: 'GET A PREPAID MASTERCARD',
        logoType: 'image',
        estimatedDelivery: '1-2 weeks',
        displayName: 'Physical Mastercard'
    },
    IAT: {
        logoType: 'image',
        image: 'ach',
        buttonText: 'USE DIRECT DEPOSIT',
        tagLines: tags.directToBank,
        estimatedDelivery: '1-2 weeks'
    },
    Interac: {
        logoType: 'image',
        image: 'interac',
        buttonText: 'USE INTERAC',
        tagLines: tags.directToBank,
        estimatedDelivery: '1-2 weeks'
    },
    'Paper Check': {
        logoType: 'text',
        logo: 'Paper Check By Mail',
        buttonText: 'USE PAPER CHECK',
        tagLines: 'Allow 1-3 extra weeks for delivery',
        logoColor: '#777777',
        estimatedDelivery: '6-8 weeks'
    },
    PayPal: {
        logoType: 'image',
        image: 'paypal',
        buttonText: 'USE PAYPAL',
        tagLines: tags.noBank,
        estimatedDelivery: '1-3 business days'
    },
    Target: {
        logoType: 'image',
        image: 'target2',
        buttonText: 'GET A TARGET CARD',
        tagLines: 'Use for your Target purchases',
        estimatedDelivery: 'Within hours'
    },
    Starbucks: {
        logoType: 'image',
        image: 'starbucks',
        buttonText: 'GET A STARBUCKS CARD',
        tagLines: 'Use for your Starbucks purchases',
        estimatedDelivery: 'Within hours'
    },
    Lowes: {
        logoType: 'image',
        image: 'lowes',
        buttonText: "GET A LOWE'S CARD",
        tagLines: "Use for your Lowe's purchases",
        estimatedDelivery: 'Within hours',
        displayName: "Lowe's"
    },
    Venmo: {
        logoType: 'image',
        image: 'venmo',
        buttonText: 'USE VENMO',
        tagLines: tags.noBank,
        estimatedDelivery: '1-3 business days'
    },
    Wire: {
        logoType: 'text',
        logo: 'Wire (PLACEHOLDER)',
        logoColor: '#777777',
        buttonText: 'USE WIRE',
        tagLines: 'Direct to your bank account',
        estimatedDelivery: '1-2 weeks'
    },
    Zelle: {
        logoType: 'image',
        image: 'zelle',
        buttonText: 'USE ZELLE',
        tagLines: tags.directToBank,
        estimatedDelivery: '1-3 business days'
    },
    Offline: {
        logoType: 'text',
        logo: 'eCheck',
        logoColor: '#777777',
        tagLines: ['Sent to your email address'],
        buttonText: 'USE EMAIL CHECK',
        estimatedDelivery: 'Within hours',
        displayName: 'eCheck'
    },
    'EFT Canada': {
        logoType: 'image',
        image: 'eft',
        buttonText: 'USE DIRECT DEPOSIT',
        tagLines: tags.directToBank,
        estimatedDelivery: '2-5 business days',
        displayName: 'Direct Deposit'
    }
};
