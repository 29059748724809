export var paypalFaq = {
    title: 'PayPal FAQs',
    questions: {
        a: {
            Q: 'What is PayPal?',
            A: 'PayPal is an online payment system that allows you to receive money with just your email address or phone number. You can have your balance in your PayPal account, and if you choose, you can also link your bank account. For more details, please visit ||paypal.com|https://paypal.com||.'
        },
        b: {
            Q: 'Do I need an existing PayPal account to select PayPal as my payment method?',
            A: 'You can choose PayPal even if you do not already have an existing account! If you do not have an existing account at the time of payment, you will receive a notification which will allow you to set up an account and receive your payment.'
        }
    }
};
