export var starbucksFaq = {
    title: 'Starbucks Gift Card Disclosure and FAQs',
    questions: {
        a: {
            Q: 'What is a Starbucks eGift Card?',
            A: 'Starbucks eGift Cards allow you to spend stored value at Starbucks online or in Starbucks stores and only require you to provide your email address.'
        },
        b: {
            Q: 'Where can I find more information about Starbucks eGift Cards?',
            A: 'Please visit ||Starbucks.com/card|https://Starbucks.com/card||.'
        }
    }
};
